import { useEffect } from "react";
import { useAdobeTarget } from "../../utils/useAdobeTarget";
import { seoSelector } from "../Seo/redux/selector/seo";
import { useSelector } from "react-redux";
import { useCurrentPathName } from "../../utils/hooks/current-pathname/CurrentPathName";
import { formatToTitleCase } from "../../utils/utils";
import { useLocation } from "react-router";

const HeaderPromotionBanner: React.FC = () => {

    // const seoConfig = useSelector(seoSelector);
    const { pathname } = useLocation();

    // const { currentPathName } = useCurrentPathName();

    // // Format the current path "/categories/power-tools" as PowerTools
    // const formattedPath = formatToTitleCase(
    //     currentPathName.replace(/-/g, ' ')
    // ).replace(/ /g, '');

    // const { triggerTargetViewWithParameters } = useAdobeTarget();

    // useEffect(() => {
    //     console.log("HeaderPromoBanner didMount");
    // }, []);

    // useEffect(() => {
    //     console.log(pathname);
    //     if (currentPathName) {
    //         const params = {
    //             PageType: '',
    //             PageName: ''
    //         };
    //         if (seoConfig && seoConfig[formattedPath]) {
    //             const currentPageType = seoConfig[formattedPath]?.page?.type;
    //             params.PageType = currentPageType;
    //         }
    //         params.PageName = formattedPath;
    //         console.group("HeaderPromoBanner");
    //         console.log(params.PageName);
    //         console.log(params.PageType)
    //         console.groupEnd();
    //         triggerTargetViewWithParameters(undefined, params, "headerBanner", true);
    //     }
    // }, [currentPathName, formattedPath, pathname, seoConfig, triggerTargetViewWithParameters]);

    return (
        <div className='adobe-target-banner' id='headerPromotionLower' key={'head-banner-' + pathname}></div>
    );
};

export { HeaderPromotionBanner };
