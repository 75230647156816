const translationKey = 'SESSION_EXPIRATION';

const SignInFormConstants = {
  EMAIL_ADDRESS_FIELD: {
    id: 'email-id',
    label: 'Email Address',
    name: 'emailId',
    type: 'email',
    sensitiveData: true,
  },
  PASSWORD_FIELD: {
    id: 'modal-password',
    label: 'Password',
  },
  SIGN_IN_BUTTON: `Sign In`,
  CANCEL: 'Cancel',
  FORGOT_PASSWORD: `${translationKey}.FORGOT_PASSWORD`,
};

export { SignInFormConstants };
