import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { AuthenticationService } from '../../../../_foundation/apis/auth/authentication.service';
import { CartService } from '../../../../_foundation/apis/cart/cart.service';
import { UsableShippingService } from '../../../../_foundation/apis/shipping/usable-shipping.service';
import { useSite } from '../../../../_foundation/hooks/usesite/useSite';
import ICartResponse from '../../../../_foundation/interface/Responses/ICartResponse';
import * as ROUTES from '../../../../constants/routes';
import {
  LOGIN_REQUESTED_ACTION,
  LOGIN_SUCCEEDED_ACTION,
} from '../../../../redux/actions/auth.actions';
import { SET_BUTTON_LOADING_ACTION } from '../../../../redux/actions/button.actions';
import {
  RESET_NETWORK_ERROR_ACTION,
  RESET_REDIRECTION_URL_ACTION,
} from '../../../../redux/actions/network-errors.actions';
import { FETCH_CART_DETAILS_SUCCESS_ACTION } from '../../../../redux/actions/order.actions';
import { FETCH_CART_ITEM_DETAILS_SUCCESS_ACTION } from '../../../../redux/actions/orderDetails.action';
import { authenticationSelector } from '../../../../redux/selectors/auth.selector';
import { buttonSelector } from '../../../../redux/selectors/button.selector';
import { networkErrorSelector } from '../../../../redux/selectors/network-errors.selector';
import { storeLocatorSelector } from '../../../../redux/selectors/storeLocator';
import {
  DELIVERY_PAGE_API_LOADING,
  DELIVERY_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
  FETCH_CHECKOUT_CART_DETAILS_DELIVERY,
  FETCH_CHECKOUT_CART_DETAILS_PAYMENT,
  FETCH_CHECKOUT_CART_DETAILS_REVIEW,
} from '../../../Checkout/redux/actions/checkout.actions';
import { NteButton } from '../../../Forms/NteButton/NteButton';
import { PasswordInput } from '../../../Forms/PasswordInput/PasswordInput';
import { TextInput } from '../../../Forms/TextInput/TextInput';
import { useTextInput } from '../../../Forms/TextInput/hooks/TextInputHooks';
import { NteAlert } from '../../../NteAlert/NteAlert';
import { SignInFormConstants } from './SignInFormConstants';
import { ErrorConstants } from '../../../Error/ErrorConstants';
import { SessionInvalidConstants } from '../../../SessionInvalid/SessionInvalidConstants';
import { autoCompleteFocusHandler } from '../../../../utils/utils';

/**
 * @interface ISignInForm
 */
interface ISignInForm {
  /**
   * @prop signInCloseHandler handles the close events.
   */
  signInCloseHandler: () => void;
}

/**
 * @component SignInForm renders the signin form.
 *
 * @param ISignInForm
 */
const SignInForm: React.FC<ISignInForm> = ({ signInCloseHandler }) => {
  const {
    EMAIL_ADDRESS_FIELD,
    PASSWORD_FIELD,
    SIGN_IN_BUTTON,
    CANCEL,
    FORGOT_PASSWORD,
  } = SignInFormConstants;

  const dispatch = useDispatch();

  const history = useHistory();

  const { t } = useTranslation();

  const { mySite } = useSite();

  const { loading } = useSelector(buttonSelector);

  const { errorCode, errorMessage, isUserAuthenticated } = useSelector(
    authenticationSelector
  );

  const { redirectionUrl } = useSelector(networkErrorSelector);

  const { currentStoreDetails } = useSelector(storeLocatorSelector);

  const { currentValue: emailAddress, onInputChange: onEmailAddressChange } =
    useTextInput();

  const { currentValue: password, onInputChange: onPasswordChange } =
    useTextInput();

  useEffect(() => {
    if (errorMessage) {
      dispatch(SET_BUTTON_LOADING_ACTION({ loading: false }));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (isUserAuthenticated) {
      if (
        redirectionUrl !== ROUTES.CHECKOUT_PAYMENT ||
        redirectionUrl !== ROUTES.CHECKOUT_DELIVERY ||
        redirectionUrl !== ROUTES.CHECKOUT_REVIEW
      ) {
        history.push(redirectionUrl);
      }

      dispatch(RESET_REDIRECTION_URL_ACTION());

      signInCloseHandler();
    }
  }, [
    dispatch,
    history,
    isUserAuthenticated,
    redirectionUrl,
    signInCloseHandler,
  ]);

  /**
   * @method checkoutSignIn is responsible for loggin in from the checkoutflow
   * and refetching the cart data from BE.
   *
   * NOTE: Not this method has been introduced since login and cart refetch
   * dispatch has some issues from the checkout flow.
   */
  const checkoutSignIn = async (): Promise<void> => {
    try {
      const loginResponse = await new AuthenticationService().authenticateUser({
        storeID: mySite.storeID,
        logonPassword: password,
        logonId: emailAddress,
      });

      dispatch(LOGIN_SUCCEEDED_ACTION(loginResponse));

      dispatch(SET_BUTTON_LOADING_ACTION({ loading: false }));

      dispatch(RESET_NETWORK_ERROR_ACTION());

      const cartResponse: ICartResponse = await CartService.getCartDetails({
        storeID: mySite.storeID,
      });

      dispatch(FETCH_CART_DETAILS_SUCCESS_ACTION(cartResponse));

      dispatch(FETCH_CART_ITEM_DETAILS_SUCCESS_ACTION(cartResponse));

      if (history.location.pathname.includes(ROUTES.CHECKOUT_DELIVERY)) {
        dispatch(FETCH_CHECKOUT_CART_DETAILS_DELIVERY(cartResponse));

        const physicalStoreId = currentStoreDetails
          ? currentStoreDetails.uniqueID
          : '';

        const usableShippingInfoResponse =
          await new UsableShippingService().getUsableShippingInfo({
            physicalStoreId,
            storeID: mySite.storeID,
            addressId: cartResponse?.orderItem[0]?.addressId,
          });

        if (usableShippingInfoResponse) {
          dispatch(DELIVERY_PAGE_API_LOADING(false));
        }

        dispatch(
          DELIVERY_USABLE_SHIPPING_INFO_SUCCESS_ACTION(
            usableShippingInfoResponse
          )
        );
      } else if (history.location.pathname.includes(ROUTES.CHECKOUT_PAYMENT)) {
        dispatch(FETCH_CHECKOUT_CART_DETAILS_PAYMENT(cartResponse));
      } else if (history.location.pathname.includes(ROUTES.CHECKOUT_REVIEW)) {
        dispatch(FETCH_CHECKOUT_CART_DETAILS_REVIEW(cartResponse));
      }
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * @method onSignInFormSubmit is responsible for
   * handling for submit event.
   *
   * @param event Contains the form field's event.
   */
  const onSignInFormSubmit = (event: any): void => {
    event.preventDefault();

    if (emailAddress && password) {
      dispatch(SET_BUTTON_LOADING_ACTION({ loading: true }));

      if (history.location.pathname.includes(ROUTES.CHECKOUT)) {
        checkoutSignIn();
      } else {
        dispatch(
          LOGIN_REQUESTED_ACTION({
            logonId: emailAddress,
            logonPassword: password,
            storeID: mySite.storeID,
          })
        );
      }
    }
  };

  return (
    <form onSubmit={onSignInFormSubmit}>
      {errorMessage && (
        <NteAlert
          messages={
            errorCode === SessionInvalidConstants.PASSWORD_EXPIRED_ERR_CODE
              ? [ErrorConstants.ERROR_LOGIN_EXPIRED]
              : [errorMessage]
          }
          severity='error'
          showClose={false}
        />
      )}
      <fieldset className='not-mui'>
        <TextInput
          {...EMAIL_ADDRESS_FIELD}
          fullWidth={true}
          onChange={onEmailAddressChange}
          value={emailAddress}
          onFocus={() => autoCompleteFocusHandler(EMAIL_ADDRESS_FIELD.id)}
        />

        <PasswordInput
          {...PASSWORD_FIELD}
          fullWidth={true}
          onChange={onPasswordChange}
          showIcon={false}
          onFocus={() => autoCompleteFocusHandler(PASSWORD_FIELD.id)}
        />

        <Typography variant='body2' className='forgot-password'>
          <Link
            to={ROUTES.FORGOT_PASSWORD}
            onClick={signInCloseHandler}
            rel='nofollow'>
            {t(FORGOT_PASSWORD)}
          </Link>
        </Typography>
      </fieldset>

      <div className='button-row'>
        <NteButton
          fullWidth={true}
          loading={loading}
          translationKey={SIGN_IN_BUTTON}
          type='submit'
          variant='contained'
        />

        <NteButton
          fullWidth={true}
          onClick={signInCloseHandler}
          translationKey={CANCEL}
          variant='outlined'
          type='button'
        />
      </div>
    </form>
  );
};

export { SignInForm };
