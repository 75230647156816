const SIGN_IN_FORM_TRANSLATION: string =
  'APP_BAR.SECONDARY_APP_BAR.ACCOUNT.SIGN_IN_FORM';

const SignInFormConstants = {
  EMAIL_ADDRESS_FIELD: {
    id: 'email-id',
    label: 'Email Address*',
    name: 'emailId',
    type: 'email',
    autoComplete: 'email',
    maxLength: 50,
    sensitiveData: true,
  },
  PASSWORD_FIELD: {
    id: 'account-password',
    label: 'Password*',
  },
  FORGOT_PASSWORD: `${SIGN_IN_FORM_TRANSLATION}.FORGOT_PASSWORD`,
  SIGN_IN_BUTTON: `${SIGN_IN_FORM_TRANSLATION}.SIGN_IN`,
  CREATE_ACCOUNT_BUTTON: `${SIGN_IN_FORM_TRANSLATION}.CREATE_ACCOUNT`,
  PASSWORD_VALIDATION: 'Password is required.',
  EMAIL_VALIDATION: 'Email address is required.',
};

export { SignInFormConstants };
