import { createAction } from '@reduxjs/toolkit';
import {
  IGetSeoAction,
  IGetSeoActionSuccess,
} from '../../../../_foundation/interface/Seo/ISeo';
import * as ACTIONTYPES from '../action-types/seo';

const GET_SEO_CONFIG_ACTION = createAction<IGetSeoAction>(
  ACTIONTYPES.GET_SEO_CONFIG
);

const GET_SEO_CONFIG_SUCCESS_ACTION = createAction<IGetSeoActionSuccess>(
  ACTIONTYPES.GET_SEO_CONFIG_SUCCESS
);


const REFRESH_SEO_ACTION = createAction(ACTIONTYPES.REFRESH_SEO);

export {
  GET_SEO_CONFIG_ACTION,
  GET_SEO_CONFIG_SUCCESS_ACTION,
  REFRESH_SEO_ACTION,
};
