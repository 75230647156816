import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedTextFieldProps,
  Typography,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useState } from 'react';
import { LUCKY_ORANGE_CONSTANTS } from '../../../constants/LuckyOrange';
import { formatAriaLabel } from '../../../utils/utils';
import { NteIconButton } from '../../NteIconButton/NteIconButton';

interface Props {
  /**
   * @prop error flag determines whether the
   * field is in the error state.
   */
  error?: boolean;

  /**
   * @prop id of the text input field.
   */
  id: string;

  /**
   * @prop label text for text input field.
   */
  label: string;

  /**
   * @prop onChange Callback fired when the value is changed.
   *
   * @param event
   * The event source of the callback.
   * Value can be accessed by event.target.value
   */
  onChange?: OutlinedTextFieldProps['onChange'];

  /**
   * @prop onFocus Callback.
   */
  onFocus?: () => void;

  /**
   * @prop placeholder text for text input field.
   */
  placeholder?: string;

  /**
   * @prop fullWidth determines whether the component needs
   * to be rendered full width with respect to its parent.
   */
  fullWidth?: boolean;

  /**
   * @prop showIcon is responsible for toggling between
   * eye icon and text for password field show/hide feature.
   */
  showIcon?: boolean;

  /**
   * @prop states if the field is required
   */
  required?: boolean;

  /**
   * @prop onBlur is the callback function called when the input field goes out of focus.
   */
  onBlur?: () => void;

  /**
   * @prop value of the password Field.
   */
  value?: string;

  /**
   * Wrapper class name.
   */
  className?: string;

  helperText?: string;

  isHeaderForm?: boolean;
}

/**
 * @component PasswordInput is responsible for rendering
 * the password field with show/hide feature.
 */
const PasswordInput: React.FC<Props> = ({
  fullWidth,
  id,
  label,
  error,
  onChange,
  onFocus,
  placeholder,
  required,
  onBlur,
  value,
  showIcon = true,
  className = '',
  helperText,
  isHeaderForm,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const [passwordCount, setPasswordCount] = useState<number>(0);

  /**
   * @method iconButtonClickHandler is responsible for toggling
   * visibility state between the password icon show/hide button.
   */
  const iconButtonClickHandler = (): void =>
    setShowPassword((showPassword) => !showPassword);

  const passwordHandler = (e: any): void => {
    if (onChange) {
      onChange(e);
    }

    setPasswordCount(e.target.value.length);
  };

  return (
    <FormControl
      style={{ marginTop: 10 }}
      fullWidth={fullWidth}
      variant='outlined'
      className={
        error
          ? `password_input ${className} error password-input-error`
          : `password_input ${className}`
      }>
      <InputLabel aria-label={formatAriaLabel(label)} htmlFor={id}>
        {label} {required && <span className='MuiInputLabel-asteriskr'>*</span>}
      </InputLabel>
      <span
        id={`${id}-counter`}
        className='sr-only'
        aria-live='polite'
        aria-atomic='true'>
        character count {passwordCount}
      </span>
      <OutlinedInput
        id={id}
        error={isHeaderForm ? false : error}
        required={required}
        style={{ marginBottom: 0 }}
        value={value}
        autoComplete='off'
        placeholder={placeholder}
        type={showPassword ? 'text' : 'password'}
        onChange={passwordHandler}
        onFocus={onFocus}
        className={`form-input-field ${LUCKY_ORANGE_CONSTANTS.SENSITIVE_DATA}`}
        onBlur={onBlur}
        inputProps={{
          'aria-live': 'polite',
          'aria-atomic': 'true',
          'aria-describedby': `${id}-counter`,
        }}
        endAdornment={
          <InputAdornment position='end'>
            <NteIconButton variant='text' onClick={iconButtonClickHandler}>
              {showIcon ? (
                <>
                  {showPassword ? (
                    <Visibility aria-hidden='true' />
                  ) : (
                    <VisibilityOff aria-hidden='true' />
                  )}
                </>
              ) : (
                <>
                  {showPassword ? (
                    <Typography aria-label='hide' variant='body2'>
                      Hide
                    </Typography>
                  ) : (
                    <Typography aria-label='show' variant='body2'>
                      Show
                    </Typography>
                  )}
                </>
              )}
            </NteIconButton>
          </InputAdornment>
        }
        labelWidth={70}
      />
      {isHeaderForm && (
        <FormHelperText error={error}>{error ? helperText : ''}</FormHelperText>
      )}
    </FormControl>
  );
};

export { PasswordInput };
