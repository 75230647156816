import { compareDesc } from 'date-fns';
import { ProductCardConstants } from '../components/Widgets/Plp/ProductCard/ProductCardConstants';
import {
  getShippingMessage,
  ProductCardSectionMessagesConstants,
} from '../components/Widgets/Plp/ProductCard/ProductCardSectionMessages/ProductCardSectionMessagesConstants';
import store from '../redux/store/index';
import { site } from '../_foundation/constants/site';
import {
  AnchorStore,
  GlobalBopis,
  LineType,
  ShipMethod,
  ShippingType,
  SoldAtStores,
  StockStatus,
  StockStatusAttribute,
  StoreBopis,
  StoreInventory,
  StoreOnly,
  StoreStatus,
  StoreStocked,
} from '../_foundation/enum/ProductList/ProductList';
import { IPricing } from '../_foundation/interface/Pricing/IPricing';
import {
  IEofferPrice,
  IProductListContentAttributes,
  IProductListContentAttributeValues,
  IProductListContents,
} from '../_foundation/interface/ProductList/IProductList';
import {
  ICartContentsItem,
  IMerchandisingAssociations,
} from '../_foundation/interface/Responses/ICartProductsResponse';
import { IShippingInfoProductsItem } from '../_foundation/interface/Responses/IShippingInfoResponse';
import {
  checkTruthy,
  convertStrToNumber,
  formatPrice as priceUtility,
  getEnumKeyByValue,
  hasKey,
  isStoreAvailable,
} from './utils';
import { IOrderItem } from '../_foundation/interface/Cart/IOrder';
import { IAttributesItem, IContentsItem, IValuesItem } from '../_foundation/interface/Responses/IProductsByPartNumbersResponse';
import { isArray } from 'lodash';

export interface ISoldAtStore {
  isSoldAtStore: boolean;
  isSoldAtMyStore: boolean;
}

/**
 * @interface IProductPrice
 */
export interface IProductPrice {
  displayPrice: number;
  offerPrice: number;
  saveAmount: string;
  swatchable: boolean;
  isProductOnSale: boolean;
  minimumQuantity: string;
  minimumOfferPrice: string;
  cartQtyMessage?: string;
  plpQtyMessage?: string;
  showQtyPopper?: boolean;
  itemPrices: string[];
  itemQuantities: string[];
  formattedDisplayPrice: string;
  formattedOfferPrice: string;
  unitPrice: number;
  isTier3Pricing: boolean;
  swatchNoPrice: boolean;
  productUnitPrice: number;
  currencyDisplayPrice: string;
  currencyOfferPrice: string;
}

/**
 * @interface IProcessPrice
 */
export interface IProcessPrice {
  productDetails:
  | IProductListContents
  | ICartContentsItem
  | IMerchandisingAssociations;
  quantity?: number;
  extendedOfferResponse?: IPricing;
  isMembership?: boolean;
  unitPriceCart?: number;
  individualProductPrice?: number;
  isCartPage?: boolean;
  isCartCard?: boolean;
  isRvi?: boolean;
  isCatalogOrder?: boolean;
  orderItemProduct?: IOrderItem;
}

/**
 * @interface IProcessTier3Pricing
 */
interface IProcessTier3Pricing {
  message: string;
  plpMessage: string;
  prices: string[];
  quantites: string[];
}

/**
 * @interface IStorepickupMessage
 */
interface IStorepickupMessage {
  productCard: IProductListContents | ICartContentsItem;
  isGlobalBopis: boolean;
  pageType: string;
  inventory?: IShippingInfoProductsItem;
  storeName: string;
  shopAllPage?: boolean;
}

const INFINITE_QUANTITY = '-1000.0';

/**
 * @global Utility that handles all functions related to cookies
 */
const ProductUtility = {
  /**
   * @method formatPrice is responsible for formatting price displayed for products.
   *
   * @param price to be formatted.
   */
  formatPrice(price: string): string {
    const commaSeparatedPrice = priceUtility(price);

    return `${commaSeparatedPrice}`;
  },

  /**
   * @method getSavePrice is responsible for getting the save amount/percentage.
   *
   * @param displayPrice of the product.
   * @param offerPrice of the product.
   */
  getSavePrice(roundedDisplayPrice: number, roundedOfferPrice: number): string {
    const saveAmount = Number(
      (roundedDisplayPrice - roundedOfferPrice).toFixed(2)
    );

    let formattedSavePrice = ProductUtility.formatPrice(saveAmount.toString());

    if (Number(saveAmount) < Number(site.minimumAmountToShowPercentage)) {
      const discountPercentage = Math.round(
        (saveAmount / roundedDisplayPrice) * 100
      );
      formattedSavePrice = `${discountPercentage}%`;
    }

    return formattedSavePrice;
  },

  /**
   * @method processPrice Processes the pricing for the following flows,
   *
   * Pricing Scenarios:
   * Normal Price.
   * Quantity Based Price.
   * Swatches.
   *
   * Pricing flows:
   * On Sale. (offer < Display)
   * Not On Sale.
   *
   * @param productDetails
   * @return IProductPrice
   */
  processPrice({
    productDetails,
    extendedOfferResponse,
    isMembership,
    quantity,
    unitPriceCart,
    isCartPage,
    isCartCard,
    individualProductPrice,
    isRvi,
    isCatalogOrder,
    orderItemProduct,
  }: IProcessPrice): IProductPrice {
    const { DISPLAY_PRICE_IDENTIFIER, OFFER_PRICE_IDENTIFIER } =
      ProductCardSectionMessagesConstants;

    // Initialize general offer price of the products.
    let offerPrice = 0;

    // Initialize general display price of the products.
    let displayPrice = 0;

    // Initialize price of one unit of each product.
    let unitPrice = 0;

    let currencyDisplayPrice = '';

    let currencyOfferPrice = '';

    /**
     * Initialize extended offer prices
     */
    let extendedOfferPrices: IEofferPrice[] = [];

    const entitledPrice =
      extendedOfferResponse &&
      extendedOfferResponse.EntitledPrice &&
      extendedOfferResponse.EntitledPrice[0];

    const isPriceRange = entitledPrice && entitledPrice.RangePrice?.length > 1;

    const isUnitOfferPrice = entitledPrice && entitledPrice.UnitPrice;

    /**
     * Construct an array of offer prices from the extended offer API response
     */
    if (extendedOfferResponse && isPriceRange) {
      entitledPrice?.RangePrice.forEach(
        ({ priceInRange, maximumQuantity, minimumQuantity }) => {
          if (priceInRange && minimumQuantity) {
            const offerPrice: IEofferPrice = {
              offerPrice: priceInRange.value.toString(),
              minimumQuantity: minimumQuantity.value.toString(),
              maximumQuantity: maximumQuantity
                ? maximumQuantity.value.toString()
                : INFINITE_QUANTITY,
            };
            extendedOfferPrices.push(offerPrice);
          }
        }
      );
    }

    const isExtended =
      isCartCard && isCatalogOrder
        ? !isCatalogOrder
        : (isMembership && checkTruthy(productDetails.extendedOffer)) ||
        (!isMembership && checkTruthy(productDetails.extendedBaseOffer));

    /**
     * Extract Display and Offer prices from the extended offer price API response or product details.
     */
    if (isExtended && quantity) {
      if (isUnitOfferPrice && entitledPrice?.UnitPrice[0].price) {
        const extendedOfferPriceValue =
          entitledPrice?.UnitPrice[0].price?.value;

        const isTwoTieredRangePricing =
          entitledPrice.RangePrice && entitledPrice?.RangePrice.length === 2;

        const isMultiTieredRangePricing =
          entitledPrice.RangePrice && entitledPrice?.RangePrice.length > 2;

        let twoTieredUnitPrice = 0;

        let multiTieredUnitPrice = 0;

        /**
         * Set unit pricing from the lowest price range value for two tiered pricing
         */
        if (
          isTwoTieredRangePricing &&
          entitledPrice.RangePrice[1].minimumQuantity &&
          entitledPrice.RangePrice[1].priceInRange &&
          quantity > entitledPrice.RangePrice[1].minimumQuantity.value - 1
        ) {
          twoTieredUnitPrice = entitledPrice.RangePrice[1].priceInRange.value;
        }

        /**
         * Set unit price from the lowest range pricing value for the respective quantity
         * range for multi-tiered pricing
         */
        isMultiTieredRangePricing &&
          entitledPrice.RangePrice.forEach((pricing) => {
            if (
              pricing?.minimumQuantity &&
              pricing?.maximumQuantity &&
              quantity >= pricing?.minimumQuantity?.value &&
              quantity <= pricing?.maximumQuantity?.value
            ) {
              multiTieredUnitPrice = pricing.priceInRange.value;
            }
          });

        if (isTwoTieredRangePricing && twoTieredUnitPrice) {
          offerPrice =
            !isCartPage && unitPriceCart
              ? unitPriceCart
              : twoTieredUnitPrice * quantity;
        } else if (isMultiTieredRangePricing && multiTieredUnitPrice) {
          offerPrice =
            !isCartPage && unitPriceCart
              ? unitPriceCart
              : quantity * multiTieredUnitPrice;
        } else {
          offerPrice =
            !isCartPage && unitPriceCart
              ? unitPriceCart
              : extendedOfferPriceValue * quantity;
        }

        unitPrice = Number(
          !isCartPage && unitPriceCart ? unitPriceCart : extendedOfferPriceValue
        );
      }

      productDetails.price.map((price) => {
        if (price.usage === DISPLAY_PRICE_IDENTIFIER) {
          displayPrice =
            quantity > 0 ? Number(price.value) * quantity : unitPrice;
        }

        return '';
      });
    } else {
      productDetails.price.map((price) => {
        if (price.usage === DISPLAY_PRICE_IDENTIFIER && quantity) {
          unitPrice = Number(price.value);
          if (isRvi === undefined) {
            isRvi = false;
          }

          displayPrice =
            (quantity > 0) && !(isRvi) ? Number(price.value) * quantity : unitPrice;

          currencyDisplayPrice = price.currency;
        }

        if (price.usage === OFFER_PRICE_IDENTIFIER && quantity) {
          unitPrice = orderItemProduct && isCatalogOrder && isCartCard
            ? Number(orderItemProduct.unitPrice)
            : Number(price.value);

          offerPrice =
            quantity > 0 ? Number(price.value) * quantity : unitPrice;
          if (isRvi) {
            offerPrice = unitPrice;


          }

          currencyOfferPrice = price.currency;
        }

        if (!isCartPage && unitPriceCart) {
          offerPrice = unitPriceCart;
        }
        return '';
      });
    }

    const isOnSale = offerPrice < displayPrice;

    // Calculate the saved price and format the price.
    let formattedSavePrice = this.getSavePrice(displayPrice, offerPrice);

    // Initialize minimum offer price based on pricing with quantity.
    let minQuantityOfferPrice = 0;

    // Initialize minimum quantity based on pricing with quantity.
    let minQuantity = 0;

    let cartQtyMessage = '';

    let plpQtyMessage = '';

    let showQtyPopper = false;

    let itemPrices: string[] = [];

    let itemQuantities: string[] = [];

    let isTier3Pricing: boolean = false;

    /**
     * If the productDetails has multiple display prices.
     *
     * Map through those prices and find the minimum offer price and the minimun quantity.
     */
    const offerPrices =
      isExtended && extendedOfferPrices.length > 0
        ? extendedOfferPrices
        : productDetails?.UserData && productDetails.UserData[0]?.eOfferPrice;

    if (offerPrices) {
      // If the eOfferPrices length is greater than 1 then it falls under Tier 3 quantity pricing scenario.
      if (quantity && offerPrices.length > 2) {
        const { message, plpMessage, prices, quantites } =
          this.processTier3Pricing(offerPrices, quantity, displayPrice);

        cartQtyMessage = message;

        plpQtyMessage = plpMessage;

        itemPrices = prices;

        itemQuantities = quantites;

        showQtyPopper = true;

        isTier3Pricing = true;
      } else {
        /**
         * Extract the minimum offer price from the list of available offer prices.
         */
        minQuantityOfferPrice = Math.min.apply(
          Math,
          offerPrices.map(({ offerPrice }) => Number(offerPrice))
        );

        /**
         * Extract the minimumQuantity from with respect to the minimum offer price.
         */
        const minimumPrice = offerPrices.find(
          (price: IEofferPrice) =>
            Number(price.offerPrice) === minQuantityOfferPrice
        );

        minQuantity = Number(minimumPrice?.minimumQuantity);
      }
    }

    // Check if the swatchable items are present or not.
    const swatchableItems =
      productDetails && productDetails.sKUs
        ? productDetails.sKUs
        : productDetails.items;

    const swatchable = Boolean(swatchableItems && swatchableItems.length > 0);

    // Initialize the maximum display price for the products under skus.
    let maxSkuDisplayPrice = 0;

    // Initialize the minimum offer price for the products under skus.
    let miniSkuOfferPrice = 0;

    if (swatchable) {
      const miniDisplayPrices: number[] = [];
      const miniOfferPrices: number[] = [];

      /**
       * Extract all the display and offer prices from all
       * the items in the skus.
       */
      swatchableItems?.map(({ price, buyable }) => {
        let displayPrices = 0;

        let offerPrices = 0;

        let unitPrice = 0;

        if (checkTruthy(buyable)) {
          price.map(({ usage, value, currency }) => {
            if (usage === DISPLAY_PRICE_IDENTIFIER && Number(value) > 0) {
              unitPrice = Number(value);
              displayPrices = quantity ? unitPrice * quantity : unitPrice;
              currencyDisplayPrice = currency;
            }

            if (usage === OFFER_PRICE_IDENTIFIER && Number(value) > 0) {
              unitPrice = Number(value);
              offerPrices = quantity ? unitPrice * quantity : unitPrice;
              currencyOfferPrice = currency;
            }

            return 0;
          });
        }

        miniDisplayPrices.push(displayPrices);

        offerPrices > 0 && miniOfferPrices.push(offerPrices);

        return price;
      });

      // Extract the maximum display price.
      maxSkuDisplayPrice = Math.max.apply(Math, miniDisplayPrices);

      // Extract the minimum offer price.
      miniSkuOfferPrice =
        miniOfferPrices && miniOfferPrices.length > 0
          ? Math.min.apply(Math, miniOfferPrices)
          : 0;
      if (maxSkuDisplayPrice - miniSkuOfferPrice > 0) {
        formattedSavePrice = this.formatPrice(
          (maxSkuDisplayPrice - miniSkuOfferPrice).toString()
        );
      }
    }

    const formattedDisplayPrice = swatchable
      ? this.formatPrice(miniSkuOfferPrice.toString())
      : this.formatPrice(displayPrice.toString());

    const formattedOfferPrice = this.formatPrice(offerPrice.toString());
    return {
      displayPrice,
      isProductOnSale: isOnSale,
      offerPrice,
      saveAmount: formattedSavePrice,
      swatchable,
      minimumQuantity: minQuantity.toString(),
      minimumOfferPrice: this.formatPrice(minQuantityOfferPrice.toString()),
      itemQuantities,
      itemPrices,
      cartQtyMessage,
      plpQtyMessage,
      showQtyPopper,
      formattedDisplayPrice,
      formattedOfferPrice,
      unitPrice,
      isTier3Pricing,
      swatchNoPrice: Boolean(miniSkuOfferPrice === 0),
      productUnitPrice: (isRvi) ? displayPrice : displayPrice / (quantity || 1) || 0,
      currencyDisplayPrice,
      currencyOfferPrice,
    };
  },

  isProductBopisEligible(productDetails: IProductListContents): boolean {
    const { SOLD_AT_STORE_IDENTIFIER } = ProductCardSectionMessagesConstants;

    const currentStore = store.getState().storeLocator?.currentStoreDetails;

    if (productDetails && currentStore && currentStore.uniqueID) {
      const soldAtStores = productDetails?.attributes?.filter(
        ({ identifier }) =>
          identifier.toLowerCase() === SOLD_AT_STORE_IDENTIFIER.toLowerCase()
      );

      const storeValues = soldAtStores?.map(({ values }) => values)[0];

      let isStoreAvailable = -1;

      if (storeValues && storeValues[0]) {
        const values = storeValues[0]?.value as unknown as Array<string>;

        if (Array.isArray(values)) {
          isStoreAvailable = values?.findIndex(
            (storeId) => storeId === currentStore?.uniqueID
          );
        }
      }

      return isStoreAvailable !== -1;
    }

    return false;
  },

  /**
   * Bug 98554
   * This method is used to validate if the anchor
   * store is eligible for local delviery.
   */
  isSameDayDeliveryEligible(): boolean {
    const { LOCAL_DELIVERY } = ProductCardSectionMessagesConstants;

    if (isStoreAvailable()) {
      const currentStore = store.getState().storeLocator?.currentStoreDetails;

      if (currentStore) {
        return checkTruthy(
          currentStore?.Attribute?.filter(
            ({ displayName }) =>
              displayName.toLowerCase() === LOCAL_DELIVERY.toLowerCase()
          )[0]?.value
        );
      }
    }

    return false;
  },

  isCurrentStoreOpen(): boolean {
    const { STORE_WEB_STATUS, STORE_STATUS } =
      ProductCardSectionMessagesConstants;

    const currentStore = store.getState().storeLocator?.currentStoreDetails;

    if (currentStore?.uniqueID) {
      let storeStatus = currentStore?.Attribute?.filter(
        ({ displayName }) =>
          displayName.toLowerCase() === STORE_WEB_STATUS.toLowerCase()
      )[0]?.value.toLowerCase();
      return (
        storeStatus === STORE_STATUS.OPEN.toLowerCase() ||
        storeStatus === STORE_STATUS.NOW_OPEN.toLowerCase()
      );
    }

    return false;
  },

  /**
   * @method isProductStore Checks if the an item is bopis eligible for the currently selected anchor store.
   *
   * @param productDetails
   * @param pageType
   * @returns isSameDayDelivery bool flag
   */
  isProductStoreAvailable(
    productDetails: IProductListContents | ICartContentsItem
  ): ISoldAtStore {
    const { SOLD_AT_STORE_IDENTIFIER } = ProductCardSectionMessagesConstants;

    let soldAtStore: ISoldAtStore = {
      isSoldAtStore: false,
      isSoldAtMyStore: false,
    };

    const soldAtStores = productDetails?.attributes?.filter(
      ({ identifier }) =>
        identifier.toLowerCase() === SOLD_AT_STORE_IDENTIFIER.toLowerCase()
    );

    if (soldAtStores && soldAtStores.length > 0) {
      if (isStoreAvailable()) {
        const currentStore = store.getState().storeLocator?.currentStoreDetails;

        if (currentStore) {
          if (soldAtStores && soldAtStores?.length !== 0) {
            const storeValues = soldAtStores?.map(({ values }) => values)[0];

            let isStoreAvailable = -1;

            if (storeValues && storeValues.length > 1) {
              const values =
                storeValues as unknown as Array<IProductListContentAttributeValues>;

              if (Array.isArray(values)) {
                isStoreAvailable = values?.findIndex(
                  (value) => value.value === currentStore?.uniqueID
                );

                if (isStoreAvailable === -1) {
                  /**
                   * Attribute present but value absent
                   */
                  soldAtStore = {
                    isSoldAtStore: true,
                    isSoldAtMyStore: false,
                  };
                }
              }
            }

            if (storeValues && storeValues[0] && storeValues[0]?.value) {
              const values = storeValues[0]?.value as unknown as Array<string>;

              if (Array.isArray(values)) {
                isStoreAvailable = values?.findIndex(
                  (storeId) => storeId === currentStore?.uniqueID
                );
              }
            }

            if (isStoreAvailable !== -1) {
              soldAtStore = {
                isSoldAtStore: true,
                isSoldAtMyStore: true,
              };
            } else {
              soldAtStore = {
                isSoldAtStore: true,
                isSoldAtMyStore: false,
              };
            }
          }
        }
      } else {
        soldAtStore = {
          isSoldAtStore: false,
          isSoldAtMyStore: false,
        };
      }
    } else {
      soldAtStore = {
        isSoldAtStore: false,
        isSoldAtMyStore: false,
      };
    }
    return soldAtStore;
  },

  /**
   * @method getLineType Returns a line type based on the product attributes.
   *
   * @param productDetails
   * @returns LineType
   */
  getLineType(
    productDetails: IProductListContents | ICartContentsItem
  ): LineType {
    const { LINE_TYPE_IDENTIFIER } = ProductCardSectionMessagesConstants;
    let lineType: LineType = LineType.S;

    productDetails?.attributes?.map(({ identifier, values }) => {
      const attributeIdentifier = identifier.toLowerCase();

      const attributeKey = values[0]?.value;

      if (attributeIdentifier === LINE_TYPE_IDENTIFIER.toLowerCase()) {
        if (hasKey(LineType, attributeKey)) {
          lineType = LineType[attributeKey];
        }
      }

      return '';
    });

    return lineType;
  },

  /**
   * @method getShippingType Returns a shipping type based on
   * lineType, shipMethod, stockStatus and storeOnly attributes.
   *
   * @param productDetails
   * @returns ShippingType
   */
  getShippingType(
    productDetails: IProductListContents | ICartContentsItem
  ): ShippingType {
    const {
      LINE_TYPE_IDENTIFIER,
      SHIP_METHOD_IDENTIFIER,
      STOCK_STATUS_IDENTIFIER,
      SHIPPING_TYPE_VALUE,
    } = ProductCardSectionMessagesConstants;

    const isStoreOnly = this.isStoreOnly(productDetails);

    let lineType = undefined;

    let shipMethod = undefined;

    let stockStatus = undefined;

    if (productDetails?.attributes && productDetails.attributes.length !== 0) {
      productDetails.attributes.map(({ identifier, values }) => {
        const attributeIdentifier = identifier.toLowerCase();

        const attributeKey = values[0]?.value;

        if (attributeIdentifier === LINE_TYPE_IDENTIFIER.toLowerCase()) {
          if (hasKey(LineType, attributeKey)) {
            lineType = LineType[attributeKey];
          }
        }

        if (attributeIdentifier === SHIP_METHOD_IDENTIFIER.toLowerCase()) {
          if (hasKey(ShipMethod, attributeKey)) {
            shipMethod = ShipMethod[attributeKey];
          }
        }

        if (attributeIdentifier === STOCK_STATUS_IDENTIFIER.toLowerCase()) {
          if (hasKey(StockStatusAttribute, attributeKey)) {
            stockStatus = StockStatusAttribute[attributeKey];
          }
        }

        return '';
      });
    }

    if (isStoreOnly) {
      return ShippingType.NOT_AVAILABLE;
    }

    if (lineType && shipMethod && stockStatus) {
      return SHIPPING_TYPE_VALUE[lineType + shipMethod + stockStatus];
    }

    if (lineType && stockStatus) {
      return SHIPPING_TYPE_VALUE[lineType + stockStatus];
    }

    if (lineType && shipMethod) {
      return SHIPPING_TYPE_VALUE[lineType + shipMethod];
    }

    if (lineType) {
      return SHIPPING_TYPE_VALUE[lineType];
    }

    return ShippingType.NOT_AVAILABLE;
  },

  /**
   * @method processShippingMessage Process the shipping message
   * based on StockStatus and ShippingType.
   *
   * @param productDetails
   * @param stockStatus
   * @return shippingMessage
   */
  processShippingMessage(
    productDetails: IProductListContents | ICartContentsItem,
    stockStatus: StockStatus,
    pageType: string
  ) {
    const {
      SHIPPING_TYPE_IDENTIFIER,
      SHIPPING_MESSAGE_PLP,
      SHIPPING_MESSAGE_PDP,
      CART_PAGE_TYPE,
      PLP_PAGE_TYPE,
      PRODUCT_PAGE,
      ITEM_PAGE,
      SAME_DAY_DELIVERY_MESSAGE,
      ELECTRONIC_KEYWORD,
      CATEGORY_PAGE,
      STOCK_STATUS_IDENTIFIER,
    } = ProductCardSectionMessagesConstants;

    let SHIPPING_MESSAGE = SHIPPING_MESSAGE_PLP;

    const shippingTypeKey = this.getShippingType(productDetails);

    const isSameDayShipping =
      this.isSameDayDeliveryEligible() &&
      this.isProductStoreAvailable(productDetails);

    const storeBopis: boolean = this.checkStoreBopis();

    if (
      pageType === CART_PAGE_TYPE ||
      pageType === PRODUCT_PAGE ||
      pageType === ITEM_PAGE
    ) {
      SHIPPING_MESSAGE = SHIPPING_MESSAGE_PDP;
    } else if (pageType === PLP_PAGE_TYPE || pageType === CATEGORY_PAGE) {
      SHIPPING_MESSAGE = SHIPPING_MESSAGE_PLP;
    }

    let shippingMessage = '';

    const shippingTypeValues = productDetails?.attributes?.filter(
      (attribute: IProductListContentAttributes) =>
        attribute.identifier === SHIPPING_TYPE_IDENTIFIER
    );

    if (
      shippingTypeValues &&
      shippingTypeValues.length !== 0 &&
      SHIPPING_MESSAGE
    ) {
      const shippingValue = shippingTypeValues[0]?.values[0]?.identifier;

      const shippingKey = getEnumKeyByValue(ShippingType, shippingValue);

      const showStoreOnly = ProductUtility.isStoreOnly(productDetails);

      const isElectronic = shippingValue
        .toLowerCase()
        .includes(ELECTRONIC_KEYWORD);

      if (hasKey(ShippingType, shippingKey) && !showStoreOnly) {
        if (!isElectronic) {
          shippingMessage =
            stockStatus && stockStatus === StockStatus.Backordered
              ? getShippingMessage(
                SHIPPING_MESSAGE[stockStatus + ShippingType[shippingKey]],
                productDetails?.backorderDate
              )
              : SHIPPING_MESSAGE[stockStatus + shippingTypeKey];
        }

        if (!stockStatus || isElectronic || shippingKey === 'US_MAIL') {
          shippingMessage = SHIPPING_MESSAGE[ShippingType[shippingKey]];
        }
      }
    } else {
      if (SHIPPING_MESSAGE) {
        if (shippingTypeKey) {
          const isElectronic = shippingTypeKey
            .toLowerCase()
            .includes(ELECTRONIC_KEYWORD);

          if (!isElectronic) {
            shippingMessage =
              stockStatus && stockStatus === StockStatus.Backordered
                ? getShippingMessage(
                  SHIPPING_MESSAGE[stockStatus + shippingTypeKey],
                  productDetails?.backorderDate
                )
                : SHIPPING_MESSAGE[stockStatus + shippingTypeKey];
          }

          if (!stockStatus) {
            shippingMessage = SHIPPING_MESSAGE[shippingTypeKey];
          }
        }
      }
    }

    /**
     * Story: 94105
     * These changes were made as part of
     * the above story. Refer matrix for
     * more details.
     */

    const isElectronic =
      shippingTypeKey &&
      shippingTypeKey.toLowerCase().includes(ELECTRONIC_KEYWORD);

    if (productDetails.attributes) {
      const hasStockStatus = productDetails.attributes.some(
        (attribute) =>
          attribute.identifier === STOCK_STATUS_IDENTIFIER &&
          attribute.values[0]?.value
      );

      if (!hasStockStatus && !isElectronic) {
        shippingMessage =
          SHIPPING_MESSAGE[StockStatus.NotAvailable + shippingTypeKey];
      }
    }

    return {
      shippingMessage,
      ...{
        ...(storeBopis &&
          isSameDayShipping && {
          sameDayShippingMessage: SAME_DAY_DELIVERY_MESSAGE,
        }),
      },
    };
  },

  /**
   * @method processAdvantageExclusives Finds whether a promotional attributes is of type Advantage Exclusives
   *
   * @param productDetails
   * @returns isAdvantageExclusives
   */
  processAdvantageExclusives(productDetails: IProductListContents): boolean {
    const { PROMOTION_IDENTIFIER, ADVANTAGE_EXCLUSIVE_IDENTIFIER } =
      ProductCardSectionMessagesConstants;

    const promotionalValues = productDetails?.attributes?.filter(
      (attribute: IProductListContentAttributes) =>
        attribute.identifier === PROMOTION_IDENTIFIER
    );

    let isAdvantageExclusives = false;

    if (promotionalValues) {
      if (Array.isArray(promotionalValues[0]?.values[0]?.identifier)) {
        const advantageExclusives = promotionalValues[0]?.values[0]
          ?.identifier as string[];

        isAdvantageExclusives =
          advantageExclusives.findIndex(
            (value) => value === ADVANTAGE_EXCLUSIVE_IDENTIFIER
          ) !== -1;
      } else {
        isAdvantageExclusives =
          promotionalValues[0]?.values[0]?.identifier ===
          ADVANTAGE_EXCLUSIVE_IDENTIFIER;
      }
    }

    return isAdvantageExclusives;
  },

  /*
  * This is a duplicate of the stock isStoreOnly function, we should update the objects in both to be compatible with each other since they are holding the same data.
  */
  isStoreOnly_contents(productCard: IContentsItem): boolean {
    let isStoreOnly: boolean = false;

    productCard?.attributes?.forEach(
      (attribute: IAttributesItem) => {
        if (
          attribute?.identifier?.includes(
            ProductCardConstants.PRODUCT_CARD_SECTION3.STORE_ONLY
          )
        ) {
          attribute?.values?.forEach(
            (attributeValue: IValuesItem) => {
              const value = Array.isArray(attributeValue?.value) ? attributeValue?.value[0] : attributeValue?.value;
              if (checkTruthy(value.toLowerCase())) {
                isStoreOnly = true;
              }
            }
          );
        }
      }
    );

    return isStoreOnly;
  },

  isStoreOnly(productCard: IProductListContents | ICartContentsItem): boolean {
    let isStoreOnly: boolean = false;

    productCard?.attributes?.forEach(
      (attribute: IProductListContentAttributes) => {
        if (
          attribute?.identifier?.includes(
            ProductCardConstants.PRODUCT_CARD_SECTION3.STORE_ONLY
          )
        ) {
          attribute?.values?.forEach(
            (attributeValue: IProductListContentAttributeValues) => {
              if (checkTruthy(attributeValue?.value.toLowerCase())) {
                isStoreOnly = true;
              }
            }
          );
        }
      }
    );

    return isStoreOnly;
  },

  isDateAvailable(date: Date): boolean {
    if (date) {
      return compareDesc(new Date(date), new Date()) === 1;
    }

    return false;
  },

  isSoldAtStores(
    productCard: IProductListContents | ICartContentsItem
  ): boolean {
    let soldAtStores: boolean = false;

    productCard?.attributes?.forEach(
      (attribute: IProductListContentAttributes) => {
        if (
          attribute?.identifier ===
          ProductCardConstants.PRODUCT_CARD_SECTION3.SOLD_AT_STORES
        ) {
          soldAtStores = true;
        }
      }
    );

    return soldAtStores;
  },

  isStoreStocked(
    productCard: IProductListContents | ICartContentsItem
  ): boolean {
    const { STORE_STOCKED } = ProductCardSectionMessagesConstants;

    const storeStocked: boolean = checkTruthy(
      productCard?.attributes?.filter(
        ({ identifier }) => identifier === STORE_STOCKED
      )[0]?.values[0]?.value
    );

    return storeStocked;
  },

  productInventory(
    productCard: IProductListContents | ICartContentsItem
  ): number {
    const inventories: any = productCard;

    if (inventories['inventories.total.quantity']) {
      return Number(inventories['inventories.total.quantity']);
    }

    return 0;
  },

  getSoldAtStores(
    productCard: IProductListContents | ICartContentsItem
  ): SoldAtStores {
    const isSoldAtStores: ISoldAtStore =
      this.isProductStoreAvailable(productCard);

    // SoldAtStore -- value exist
    if (isSoldAtStores.isSoldAtStore && isSoldAtStores.isSoldAtMyStore) {
      return SoldAtStores.SoldAtStores;
    }

    // SoldAtstore attribute Absent
    if (!isSoldAtStores.isSoldAtMyStore && !isSoldAtStores.isSoldAtStore) {
      return SoldAtStores.NotSoldAtStores;
    }

    //sold at store attribute present but value for paricular store is absent
    if (isSoldAtStores.isSoldAtStore && !isSoldAtStores.isSoldAtMyStore) {
      return SoldAtStores.NotSoldAtMyStore;
    }

    return SoldAtStores.NotSoldAtMyStore;
  },

  getStoreOnly(
    productCard: IProductListContents | ICartContentsItem
  ): StoreOnly {
    const isStoreOnly = this.isStoreOnly(productCard);

    if (isStoreOnly) {
      return StoreOnly.StoreOnly;
    }

    return StoreOnly.NotStoreOnly;
  },

  getStoreStocked(
    productCard: IProductListContents | ICartContentsItem
  ): StoreStocked {
    const isStoreStocked = this.isStoreStocked(productCard);

    if (isStoreStocked) {
      return StoreStocked.StoreStocked;
    }

    return StoreStocked.NotStoreStocked;
  },

  getStoreBopis(): StoreBopis {
    const { BOPIS_AVAILABILITY } = ProductCardSectionMessagesConstants;

    const currentStore = store.getState().storeLocator?.currentStoreDetails;

    const isLocalDeliveryAvailable = checkTruthy(
      currentStore?.Attribute?.filter(
        ({ displayName }) =>
          displayName.toLowerCase() === BOPIS_AVAILABILITY.toLowerCase()
      )[0]?.value
    );

    if (isLocalDeliveryAvailable) {
      return StoreBopis.StoreBopis;
    }

    return StoreBopis.NotStoreBopis;
  },

  getTemporarilyClosedStatus(): boolean {
    const { STORE_WEB_STATUS, STORE_STATUS } =
      ProductCardSectionMessagesConstants;

    const currentStore = store.getState().storeLocator?.currentStoreDetails;

    if (currentStore) {
      const temporarilyClosed = currentStore.Attribute?.filter(
        (data) => data.name.toLowerCase() === STORE_WEB_STATUS.toLowerCase()
      );

      if (
        temporarilyClosed &&
        temporarilyClosed[0]?.value.toLowerCase() ===
        STORE_STATUS.TEMPORARILY_CLOSED.toLowerCase()
      ) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  },

  checkStoreBopis(): boolean {
    const { BOPIS_AVAILABILITY } = ProductCardSectionMessagesConstants;

    const currentStore = store.getState().storeLocator?.currentStoreDetails;

    const isStoreBopisAvailable = currentStore?.Attribute.find(
      ({ displayName }) =>
        displayName.toLowerCase() === BOPIS_AVAILABILITY.toLowerCase()
    );

    if (checkTruthy(isStoreBopisAvailable?.value)) {
      return true;
    }

    return false;
  },

  getStorestatus(): StoreStatus {
    const isStoreOpen = this.isCurrentStoreOpen();

    if (isStoreOpen) {
      return StoreStatus.Open;
    }

    return StoreStatus.Close;
  },

  getAnchorStore(): AnchorStore {
    if (isStoreAvailable()) {
      return AnchorStore.Selected;
    }

    return AnchorStore.NotSelected;
  },

  getStoreInventory(
    productCard: IProductListContents | ICartContentsItem,
    pageType: string,
    inventory?: IShippingInfoProductsItem
  ): { storeInventory: StoreInventory; storeQuantity: string } {
    if (inventory) {
      const { NA, PRODUCT_PAGE, ITEM_PAGE, CART_PAGE_TYPE } =
        ProductCardSectionMessagesConstants;

      const currentStore = store.getState().storeLocator?.currentStoreDetails;

      let storeInventory = NA;

      if (currentStore && currentStore.uniqueID) {
        if (
          pageType === PRODUCT_PAGE ||
          pageType === ITEM_PAGE ||
          pageType === CART_PAGE_TYPE
        ) {
          storeInventory = inventory.storeInventory;
        } else {
          storeInventory = this.productInventory(productCard).toString();
        }
      }
     
      if (Number(storeInventory) > 0) {
        return {
          storeInventory: StoreInventory.GreaterThanZero,
          storeQuantity: storeInventory,
        };
      } else if (Number(storeInventory) === -2) {
        return {
          storeInventory: StoreInventory.UNKNOWN,
          storeQuantity: storeInventory,
        };
      } else if ((Number(storeInventory) === 0) || (Number(storeInventory) === -1)){
        return {
          storeInventory: StoreInventory.LesserThanEqualToZero,
          storeQuantity: storeInventory,
        };
      }

      if (storeInventory === NA) {
        return {
          storeInventory: StoreInventory.NA,
          storeQuantity: storeInventory,
        };
      } 

      //return { storeInventory: StoreInventory.NA, storeQuantity: '' };
    }

    return { storeInventory: StoreInventory.UNKNOWN, storeQuantity: '' };
  },

  getGlobalBopis(isGlobalBopis: boolean): GlobalBopis {
    if (isGlobalBopis) {
      return GlobalBopis.GlobalBopis;
    }

    return GlobalBopis.NotGlobalBopis;
  },

  /**
   * @method constructStorePickup Constructs the store pick up message based on product and anchor store attributes.
   *
   * @param productCard
   * @param isGlobalBopis
   * @param pageType
   * @returns Store pick up message
   */
  constructStorePickup({
    inventory,
    isGlobalBopis,
    pageType,
    productCard,
    storeName,
    shopAllPage,
  }: IStorepickupMessage): {
    storePickUpMessage: string;
    storeQuantity: string;
  } {
    const {
      STORE_PICKUP_PLP,
      STORE_PICKUP_PDP_CART,
      PRODUCT_PAGE,
      ITEM_PAGE,
      CART_PAGE_TYPE,
      PLP_PAGE_TYPE,
      CONTENT_PAGE,
    } = ProductCardSectionMessagesConstants;

    let storePickUp;

    let storePickUpMessage = '';

    const { storeInventory, storeQuantity } = this.getStoreInventory(
      productCard,
      pageType,
      inventory
    );

    const anchorStore: AnchorStore = this.getAnchorStore();

    const soldAtStore: SoldAtStores = this.getSoldAtStores(productCard);

    const storeOnly: StoreOnly = this.getStoreOnly(productCard);

    const storeStocked: StoreStocked = this.getStoreStocked(productCard);

    const globalBopis: GlobalBopis = this.getGlobalBopis(isGlobalBopis);

    const storeBopis: StoreBopis = this.getStoreBopis();

    const storeStatus: StoreStatus = this.getStorestatus();

    const isTemporarilyClosed: boolean = this.getTemporarilyClosedStatus();

    if (pageType === PLP_PAGE_TYPE || shopAllPage) {
      storePickUp = STORE_PICKUP_PLP;
    } else if (
      pageType === CART_PAGE_TYPE ||
      pageType === PRODUCT_PAGE ||
      pageType === ITEM_PAGE
    ) {
      storePickUp = STORE_PICKUP_PDP_CART;
    } else {
      storePickUp = STORE_PICKUP_PLP;
    }

    if (storePickUp && !isTemporarilyClosed) {
      if (
        pageType === PLP_PAGE_TYPE ||
        shopAllPage ||
        pageType === CONTENT_PAGE
      ) {
        storePickUpMessage =
          storePickUp[
          anchorStore +
          soldAtStore +
          storeOnly +
          storeStocked +
          globalBopis +
          storeBopis +
          storeStatus
          ];
      } else {
        if (!isTemporarilyClosed)
          storePickUpMessage =
            storePickUp[
            anchorStore +
            soldAtStore +
            storeOnly +
            storeStocked +
            globalBopis +
            storeBopis +
            storeStatus +
            storeInventory
            ];
      }
    }

    if (
      !storePickUpMessage &&
      !storeName &&
      !isTemporarilyClosed &&
      isGlobalBopis
    ) {
      storePickUpMessage = 'Not Available';
    }

    /**
     * Store TemporarilyClosed Scenario
     */
    if (isTemporarilyClosed && storeName) {
      storePickUpMessage = `Not available at Store-Name`;
    }

    /**
     * Anchor store not set and Global Bopis OFF
     */
    if (!storePickUpMessage && !storeName && !isGlobalBopis) {
      storePickUpMessage = `No stores near you -`;
    }
    return { storePickUpMessage, storeQuantity };
  },

  /**
   * @method processTier3Pricing Processes the offer price to display cart item's quantity price message based
   * on the quantites for each item selected by the user.
   *
   * @param offerPrices List of offer prices that needs to be displayed based on the item quantity.
   * @param quantity Quantity of a cart item.
   * @returns IProcessTier3Pricing
   */
  processTier3Pricing(
    offerPrices: IEofferPrice[],
    quantity: number,
    displayPrice: number
  ): IProcessTier3Pricing {
    let cartPricingMessage = '';

    let plpPricingMessage = '';

    const eOfferPrices = [...offerPrices];

    const prices: string[] = [],
      quantites: string[] = [];

    let minQuantity = -1;

    /**
     * Build the prices and quantites array for displaying price and quantity as a table.
     */
    eOfferPrices.forEach(
      ({ maximumQuantity, minimumQuantity, offerPrice }, index) => {
        if (index === 0 && convertStrToNumber(minimumQuantity) !== 1) {
          minQuantity = convertStrToNumber(minimumQuantity);
        }

        if (maximumQuantity === INFINITE_QUANTITY) {
          /**
           * Add quantity for a indefinite range (example: 10 +)
           */
          quantites.push(`${convertStrToNumber(minimumQuantity)} +`);
        } else {
          /**
           * Add quantity for a definite range (example: 5-10)
           */
          quantites.push(
            `${convertStrToNumber(minimumQuantity)}-${convertStrToNumber(
              maximumQuantity
            )}`
          );
        }

        prices.push(`${this.formatPrice(offerPrice)} ea.`);

        return '';
      }
    );

    /**
     * Adds the minimum range from 1 if it's not present by default.
     */
    if (minQuantity !== -1) {
      quantites.unshift(`1-${minQuantity}`);

      prices.unshift(this.formatPrice(displayPrice.toString()));
    }

    // Retrive the index for the final price range.
    const finalPriceRangeIndex = eOfferPrices.findIndex(
      ({ maximumQuantity }) => maximumQuantity === INFINITE_QUANTITY
    );

    // Strip out the final price range.
    const finalPriceRange = eOfferPrices.splice(finalPriceRangeIndex, 1)[0];

    let currentQtyIndex = -1;

    // Price range index is retrived based on the factor whether the quantity lies within a specific quantity range.
    eOfferPrices.forEach(({ maximumQuantity, minimumQuantity }, index) => {
      if (
        quantity >= convertStrToNumber(minimumQuantity) &&
        quantity <= convertStrToNumber(maximumQuantity)
      ) {
        currentQtyIndex = index;
      }

      if (quantity >= convertStrToNumber(finalPriceRange.minimumQuantity)) {
        currentQtyIndex = index;
      }

      return '';
    });

    const priceValues = eOfferPrices[currentQtyIndex + 1];

    const secondPriceValue = eOfferPrices[1];

    if (secondPriceValue) {
      plpPricingMessage = `Buy ${convertStrToNumber(
        secondPriceValue.minimumQuantity
      )}-${convertStrToNumber(
        secondPriceValue.maximumQuantity
      )} at ${this.formatPrice(secondPriceValue.offerPrice)} ea.`;
    }

    if (priceValues) {
      // If the pricevalues is available then display the price message for quantity range.
      cartPricingMessage = `Buy ${convertStrToNumber(
        priceValues.minimumQuantity
      )}-${convertStrToNumber(
        priceValues.maximumQuantity
      )} at ${this.formatPrice(priceValues.offerPrice)} ea.`;
    } else {
      // If the pricevalues is not available then display the price message for specific quantity or more.
      cartPricingMessage = `Buy ${convertStrToNumber(
        finalPriceRange.minimumQuantity
      )} or more at ${this.formatPrice(finalPriceRange.offerPrice)} ea.`;
    }

    return {
      message: cartPricingMessage,
      plpMessage: plpPricingMessage,
      prices,
      quantites,
    };
  },
  showCallStoreMsg(manufacturer: string) {
    return (
      ProductCardSectionMessagesConstants.SHOW_CALL_STORE_BRAND.length > 0 &&
      ProductCardSectionMessagesConstants.SHOW_CALL_STORE_BRAND.includes(
        manufacturer.toLowerCase()
      )
    );
  },
};

export { ProductUtility };
