import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as ROUTES from '../../../../../../constants/routes';
import { REGISTER_USER } from '../../../../../../constants/routes';
import { LOGIN_REQUESTED_ACTION } from '../../../../../../redux/actions/auth.actions';
import { SET_BUTTON_LOADING_ACTION } from '../../../../../../redux/actions/button.actions';
import { authenticationSelector } from '../../../../../../redux/selectors/auth.selector';
import { buttonSelector } from '../../../../../../redux/selectors/button.selector';
import { useSite } from '../../../../../../_foundation/hooks/usesite/useSite';
import { ErrorConstants } from '../../../../../Error/ErrorConstants';
import { NteButton } from '../../../../../Forms/NteButton/NteButton';
import { PasswordInput } from '../../../../../Forms/PasswordInput/PasswordInput';
import { TextInput } from '../../../../../Forms/TextInput/TextInput';
import { NteAlert } from '../../../../../NteAlert/NteAlert';
import { SessionInvalidConstants } from '../../../../../SessionInvalid/SessionInvalidConstants';
import { SignInFormConstants } from './SignInFormConstants';
import { autoCompleteFocusHandler } from '../../../../../../utils/utils';

/**
 * @interface ISignInForm
 */
interface ISignInForm {
  /**
   * @prop signInFormCloseHandler handles the close events
   * for the SignInForm component.
   */
  signInFormCloseHandler?: () => void;
}

/**
 * @component SignInForm renders the signin form.
 *
 * @param ISignInForm
 */
const SignInForm: React.FC<ISignInForm> = ({ signInFormCloseHandler }) => {
  const {
    EMAIL_ADDRESS_FIELD,
    PASSWORD_FIELD,
    CREATE_ACCOUNT_BUTTON,
    FORGOT_PASSWORD,
    SIGN_IN_BUTTON,
  } = SignInFormConstants;

  const { t } = useTranslation();

  const { loading } = useSelector(buttonSelector);

  const { errorCode, errorMessage, isUserAuthenticated } = useSelector(
    authenticationSelector
  );

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const { mySite } = useSite();

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    if (errorMessage) {
      dispatch(SET_BUTTON_LOADING_ACTION({ loading: false }));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (isUserAuthenticated && signInFormCloseHandler) {
      signInFormCloseHandler();
    }
  }, [isUserAuthenticated, signInFormCloseHandler]);

  // const {
  //   currentValue: emailAddress,
  //   onInputChange: onEmailAddressChange,
  //   error: emailErr,
  // } = useTextInput();

  // const {
  //   currentValue: password,
  //   onInputChange: onPasswordChange,
  //   error: passwordErr,
  // } = useTextInput();

  const [emailAddress, setEmailAddress] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const createAccountHandler = () => {
    history.push(REGISTER_USER);

    if (signInFormCloseHandler) {
      signInFormCloseHandler();
    }
  };

  /**
   * @method onSignInFormSubmit is responsible for
   * handling for submit event.
   *
   * @param event Contains the form field's event.
   */
  const onSignInFormSubmit = (event: any): void => {
    event.preventDefault();
    if (emailAddress.length > 0) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    if (password.length > 0) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
    if (emailAddress && password) {
      dispatch(SET_BUTTON_LOADING_ACTION({ loading: true }));

      dispatch(
        LOGIN_REQUESTED_ACTION({
          logonId: emailAddress,
          logonPassword: password,
          storeID: mySite?.storeID,
        })
      );
    }
  };

  function handleEmailOnchange(e: any) {
    if (e.target.value.length < 1) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmailAddress(e.target.value);
  }

  return (
    <form onSubmit={onSignInFormSubmit}>
      {errorMessage && (
        <NteAlert
          messages={
            errorCode === SessionInvalidConstants.PASSWORD_EXPIRED_ERR_CODE
              ? [ErrorConstants.ERROR_LOGIN_EXPIRED]
              : [errorMessage]
          }
          severity='error'
          showClose={false}
        />
      )}
      <fieldset className='not-mui'>
        <TextInput
          {...EMAIL_ADDRESS_FIELD}
          fullWidth={true}
          onChange={handleEmailOnchange}
          onFocus={() => autoCompleteFocusHandler(EMAIL_ADDRESS_FIELD.id)}
          value={emailAddress}
          error={emailError}
          isHeaderForm={true}
          helperTextprop={
            emailError ? SignInFormConstants.EMAIL_VALIDATION : ''
          }
        />

        <PasswordInput
          {...PASSWORD_FIELD}
          fullWidth={true}
          onChange={(e: any) => {
            if (e.target.value.length < 1) {
              setPasswordError(true);
            } else {
              setPasswordError(false);
            }
            setPassword(e.target.value);
          }}
          onFocus={() => autoCompleteFocusHandler(PASSWORD_FIELD.id)}
          showIcon={false}
          error={passwordError}
          isHeaderForm={true}
          helperText={
            passwordError ? SignInFormConstants.PASSWORD_VALIDATION : ''
          }
        />

        <Typography variant='body2' className='forgot-password'>
          <Link
            to={ROUTES.FORGOT_PASSWORD}
            onClick={signInFormCloseHandler}
            rel='nofollow'>
            {t(FORGOT_PASSWORD)}
          </Link>
        </Typography>
      </fieldset>
      <div className='button-row'>
        <NteButton
          loading={loading}
          translationKey={SIGN_IN_BUTTON}
          type='submit'
          className='sign-in'
          size='small'
          variant='contained'
        />

        <NteButton
          onClick={createAccountHandler}
          size='small'
          translationKey={CREATE_ACCOUNT_BUTTON}
          variant='outlined'
          type='button'
        />
      </div>
    </form>
  );
};

export { SignInForm };
