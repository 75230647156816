import { AppBar, Divider } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useCheckout } from '../Checkout/hooks/CheckoutHooks';
import { HeaderMessage } from '../HeaderBanner/HeaderMessage';
import { PrimaryNavBar } from '../Widgets/AppBar/PrimaryNavBar/PrimaryNavBar';
import { SecondaryNavBar } from '../Widgets/AppBar/SecondaryNavBar/SecondaryNavBar';
import { ReactComponent as NTE_LOGO_BLACK } from '../../theme/assets/logo_blk-gold.svg';
import { useHistory } from 'react-router';
import { setLocalStorage } from '../../utils/utils';

/**
 * @component NteAppBar is responsible for
 * rendering the NteAppBar component.
 */
const NteAppBar: React.FC = () => {
  const { isCheckout } = useCheckout();
  const history = useHistory();

  useEffect(() => {
    if (history.action === 'POP') setLocalStorage('listner', false);
  }, []);

  return (
    <>
      <div className='print-logo'>
        {' '}
        {/* To display when user try to print the page */}
        <NTE_LOGO_BLACK aria-labelledby='NTElogo Icon' role={'img'} />
      </div>
      <div className='print-exclude'>
        {' '}
        {/* Hides the header when user prints a page */}
        <HeaderMessage />
        <AppBar position='static'>
          <div className='header_wrapper'>
            {!isCheckout && (
              <>
                {/* Secondary NavBar */}
                <SecondaryNavBar />

                {/* Divider between Primary and Secondary NavBar */}
                <Divider className='header_divider' />
              </>
            )}

            {/* Primary NavBar */}
            <PrimaryNavBar />
          </div>
        </AppBar>
      </div>
    </>
  );
};

export { NteAppBar };
