import { AxiosRequestConfig, CancelToken } from 'axios';
import qs from 'qs';
import { SeoConstants } from '../../../../components/Seo/SeoConstants';
import { USER_CONTRACT } from '../../../constants/cookie';
import { site } from '../../../constants/site';
import { ITopBrands } from '../../../interface/Brands/IBrands';
import {
  IInventoryInfoRequest,
  IShippingInfoRequest,
} from '../../../interface/Cart/IOrder';
import {
  IDealOfTheDayProduct,
  IFetchCategoryByIdentifier,
  IFetchProductList,
  IProductPromoContent,
} from '../../../interface/ProductList/IProductList';
import IProductsByPartNumbersResponse, {
  IContentsItem,
  IMerchAssociations,
} from '../../../interface/Responses/IProductsByPartNumbersResponse';
import validateIProductsByPartNumbersResponse from '../../../interface/Responses/IProductsByPartNumbersResponse.validator';
import IProductsResponse, {
  ICatalogEntryViewItem,
} from '../../../interface/Responses/IProductsResponse';
import validateIProductsResponse from '../../../interface/Responses/IProductsResponse.validator';
import IShippingInfoResponse from '../../../interface/Responses/IShippingInfoResponse';
import { makeRequest } from '../../axios/axiosConfig';
import { ProductServiceConstants } from './products.service.constants';

/**
 * @interface ITopBrandsParam
 */
export interface IFetchProduct {
  storeID: string;
  id?: string[];
  partNumbers?: string[];
  currentItemId?: string;
  wishListPage?: boolean;
  pageNumber?: string;
  pageSize?: string;
  wishListId?: string;
  cancelToken?: CancelToken;
}

/**
 * @interface ICatalogEntryProduct
 */
export interface ICatalogEntryProduct {
  storeID: string;
  parentCatalogEntryID: string;
}

/**
 * @interface ITopBrandsParam
 */
interface ITopBrandsParam {
  categoryID: string;
  storeID: string;
}

/**
 * @interface IPrivateBrandsParam
 */
interface IPrivateBrandsParam {
  storeID: string;
}

const productsService = {
  /**
   * @method fetchTopBrands Fetches top brands data based on the given category and store id.
   *
   * @param ITopBrandsParam
   */
  async fetchTopBrands({
    categoryID,
    storeID,
  }: ITopBrandsParam): Promise<ITopBrands> {
    const { TOP_BRANDS_URL } = ProductServiceConstants;

    const topBrandsUrl = TOP_BRANDS_URL(categoryID, storeID);

    const userContractId = sessionStorage.getItem(USER_CONTRACT);

    const queryParams = {
      ...{ ...(userContractId && { contractId: userContractId }) },
    };

    try {
      const request: AxiosRequestConfig = {
        url: topBrandsUrl,
        method: 'GET',
        params: queryParams,
      };

      const topBrandsResponse = await makeRequest(request);

      return topBrandsResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method fetchProductsByCategory Fetches Product promo content for a product Id.
   *
   * @param categoryId - Product Id
   * @param storeId - Store Id from my site
   */
  async fetchProductsByCategory({
    parentCategoryID,
    storeID,
    catalogID,
    pageSize,
    pageNumber,
    orderBy,
    facet,
    maxPrice,
    minPrice,
    cancelToken,
    isBrandPlp,
    tokenExternalValue,
    isSeeAllDeals,
    promotionAttributeId,
    allDealsNonAdvantageFilter,
    allDealsAdvantageFilter,
    profileName,
    dealsFacet,
    isCollectionPlp,
    isPromotionPlp,
  }: IFetchProductList): Promise<any> {
    const { PRODUCT_BY_CATEGORY_URL, PRODUCT_BY_CATEGORY_BRAND_URL } =
      ProductServiceConstants;

    const { PRODUCT_PATH } = SeoConstants;

    let facetValues = facet
      ?.map((value) => value.split('&facet=').filter((value) => value))
      .join()
      .split(',');

    /**
     * Encode the facet values
     */
    facetValues = facetValues?.map((value) => encodeURIComponent(value));

    /**
     * Initialize deals filter
     */
    let dealsFilter = allDealsAdvantageFilter
      ? allDealsAdvantageFilter
      : allDealsNonAdvantageFilter;

    /**
     *
     * If a 'Promotion' facet value is present
     * in facetValues, Update the dealsFilter
     * with the all facetValues and empty facetValues.
     *
     */
    if (dealsFilter && facetValues && promotionAttributeId && isSeeAllDeals) {
      const promotionFacetExists = facetValues.find((value) =>
        value.includes(promotionAttributeId)
      );

      if (promotionFacetExists) {
        dealsFilter = '&facet=' + facetValues.join('&facet=');
        facetValues = [];
      }
    }

    /**
     * ADO 99985 - Solving encoding issue for "Rebates" category on Deal.
     * This works the same for other Deal categories.
     */
    let dealsFacetUrlParam = dealsFacet;
    if (dealsFacet && facetValues) {
      dealsFacetUrlParam = encodeURI(dealsFacet);
    }

    const brandFacetValue = tokenExternalValue
      ? encodeURIComponent(tokenExternalValue)
      : tokenExternalValue;

    const findProductByCategoryUrl = isBrandPlp
      ? PRODUCT_BY_CATEGORY_BRAND_URL({
        searchContext: site.searchContext,
        storeID,
        parentCategoryID,
        facetValue: brandFacetValue,
      })
      : isSeeAllDeals
        ? PRODUCT_BY_CATEGORY_URL({
          searchContext: site.searchContext,
          storeID,
          parentCategoryID,
          facetValue: dealsFilter,
          dealsFacet: dealsFacetUrlParam,
        })
        : PRODUCT_BY_CATEGORY_URL({
          searchContext: site.searchContext,
          storeID,
          parentCategoryID,
          dealsFacet: dealsFacetUrlParam,
        });

    const userContractId = sessionStorage.getItem(USER_CONTRACT);

    const queryParams = {
      catalogId: catalogID,
      profileName: profileName ? profileName : site.fetchProductsProfileName, // updated as part of 90935
      pageSize,
      pageNumber,
      orderBy,
      ...{ ...(maxPrice && { maxPrice }) },
      ...{ ...(minPrice && { minPrice }) },
      ...{ ...(userContractId && { contractId: userContractId }) },
    };

    const autoGenDealsValue = 'facets.5503.value.raw';

    /* remove auto generated value for /see-all-deals page and all-deals filter
        to make sure other filter works properly */
    if (
      isSeeAllDeals &&
      facetValues &&
      facetValues.includes(autoGenDealsValue)
    ) {
      const index = facetValues.indexOf(autoGenDealsValue);
      facetValues.splice(index, 1);
    }

    /**
     * Constructs the query param for the facets.
     *
     * Ex: ["blue", "red", "green"] => &facet=blue&facet=green&facet=green
     */
    const facetQuery = facetValues
      ? `&${qs.stringify(
        { facet: facetValues },
        {
          indices: false,
        }
      )}`
      : '';

    /**
     * Decode the URL
     * if facetVales exists.
     */
    const url =
      facetValues && facetValues.length
        ? decodeURI(findProductByCategoryUrl + facetQuery)
        : findProductByCategoryUrl;

    try {
      const request: AxiosRequestConfig = {
        url,
        method: 'GET',
        params: queryParams,
        cancelToken: cancelToken.token,
      };

      const productsByCategoryResponse: IProductsResponse =
        validateIProductsResponse(await makeRequest(request));

      if (productsByCategoryResponse.catalogEntryView) {
        productsByCategoryResponse.catalogEntryView =
          productsByCategoryResponse.catalogEntryView?.map(
            (product: ICatalogEntryViewItem) => {
              if (product?.seo?.href) {
                product.seo.href = PRODUCT_PATH + product.seo.href;
              }
              return product;
            }
          );
      }

      return productsByCategoryResponse;
    } catch (e) {
      console.error(e);

      throw e;
    }
  },

  /**
   * @method fetchProductsByStoreIdentifier Fetches Product using store identifier.
   *
   * @param categoryId - Product Id
   * @param storeId - Store Id from my site
   * @param storeIdentifier - UniqueId
   */
  async fetchProductsByStoreIdentifier({
    parentCategoryID,
    storeID,
    catalogID,
    pageSize,
    pageNumber,
    orderBy,
    facet,
    maxPrice,
    minPrice,
    cancelToken,
    storeIdentifier,
    soldInStoreAttributeId,
  }: IFetchProductList): Promise<any> {
    const { PRODUCT_BY_STORE_IDENTIFIER } = ProductServiceConstants;

    const { PRODUCT_PATH } = SeoConstants;

    const facetValues = facet
      ?.map((value) => value.split('&facet=').filter((value) => value))
      .join()
      .split(',');

    const findProductByShopIdentifierUrl = PRODUCT_BY_STORE_IDENTIFIER({
      searchContext: site.searchContext,
      storeID,
      parentCategoryID,
      storeIdentifier,
      soldInStoreAttributeId,
    });

    const userContractId = sessionStorage.getItem(USER_CONTRACT);

    const queryParams = {
      catalogId: catalogID,
      profileName: site.fetchProductsProfileName,
      pageSize,
      pageNumber,
      orderBy,
      ...{ ...(maxPrice && { maxPrice }) },
      ...{ ...(minPrice && { minPrice }) },
      ...{ ...(userContractId && { contractId: userContractId }) },
    };

    /**
     * Constructs the query param for the facets.
     *
     * Ex: ["blue", "red", "green"] => &facet=blue&facet=green&facet=green
     */
    const facetQuery = facetValues
      ? `&${qs.stringify(
        { facet: facetValues },
        {
          indices: false,
        }
      )}`
      : '';

    try {
      const request: AxiosRequestConfig = {
        url: findProductByShopIdentifierUrl + facetQuery,
        method: 'GET',
        params: queryParams,
        cancelToken: cancelToken.token,
      };

      const productsByShopIdentifierResponse: IProductsResponse =
        validateIProductsResponse(await makeRequest(request));

      if (productsByShopIdentifierResponse.catalogEntryView) {
        productsByShopIdentifierResponse.catalogEntryView =
          productsByShopIdentifierResponse.catalogEntryView?.map(
            (product: ICatalogEntryViewItem) => {
              if (product?.seo?.href) {
                product.seo.href = PRODUCT_PATH + product.seo.href;
              }
              return product;
            }
          );
      }

      return productsByShopIdentifierResponse;
    } catch (e) {
      console.error(e);

      throw e;
    }
  },

  /**
   * @method fetchProductsByCategory Fetches Product promo content for a product Id.
   *
   * @param parentCategoryId - Product Id
   */
  async fetchProductPromoContent(
    productId: string,
    storeId: string,
    cancelToken: CancelToken
  ): Promise<IProductPromoContent> {
    const { PRODUCT_PROMO_URL } = ProductServiceConstants;

    const productPromoUrl = PRODUCT_PROMO_URL(
      site.transactionContext,
      storeId,
      productId
    );

    try {
      const request: AxiosRequestConfig = {
        url: productPromoUrl,
        method: 'GET',
        cancelToken,
      };

      const productPromoResponse = await makeRequest(request);

      return productPromoResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method fetchDealOfTheDayProduct
   * @param storeId
   * @param date
   *
   * Responsible to fetch the deal of the product
   */
  async fetchDealOfTheDayProduct(
    storeId: string,
    date: string
  ): Promise<IDealOfTheDayProduct> {
    const { FETCH_DEAL_OF_THE_DAY_PRODUCT } = ProductServiceConstants;

    const dealOfTheDateUrl = FETCH_DEAL_OF_THE_DAY_PRODUCT(storeId, date);

    try {
      const dealOfTheDayRequest: AxiosRequestConfig = {
        url: dealOfTheDateUrl,
        method: 'GET',
      };

      const dealOfTheDayProdResponse = await makeRequest(dealOfTheDayRequest);

      return dealOfTheDayProdResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method fetchPrivateBrands Fetches the private brands from the backend.
   *
   * @param IPrivateBrandsParam
   */
  async fetchPrivateBrands({ storeID }: IPrivateBrandsParam): Promise<any> {
    const { PRIVATE_BRANDS_URL } = ProductServiceConstants;

    const privateBrandsUrl = PRIVATE_BRANDS_URL(storeID);

    try {
      const request: AxiosRequestConfig = {
        url: privateBrandsUrl,
        method: 'GET',
      };

      const privateBrandsResponse = await makeRequest(request);

      return privateBrandsResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method fetchProductById Fetched the productdetails by productId
   */
  async fetchProductById({ storeID, id }: IFetchProduct): Promise<any> {
    let queryParameters = new URLSearchParams();

    const { FETCH_PRODUCTS_BY_ID } = ProductServiceConstants;

    const { PRODUCT_PATH } = SeoConstants;

    const userContractId = sessionStorage.getItem(USER_CONTRACT);

    try {
      if (id && id.length > 0) {
        const name = 'id';

        id.forEach((productId) => {
          queryParameters.append(name, productId);
        });
      }

      if (userContractId) {
        queryParameters.append('contractId', userContractId);
      }
      const request: AxiosRequestConfig = {
        url: FETCH_PRODUCTS_BY_ID(storeID),
        method: 'GET',
        params: queryParameters,
      };

      const productDetailsResponse: IProductsByPartNumbersResponse =
        await makeRequest(request);

      if (productDetailsResponse.contents) {
        productDetailsResponse.contents = productDetailsResponse.contents.map(
          (product: IContentsItem) => {
            if (product.seo?.href) {
              product.seo.href = PRODUCT_PATH + product.seo.href;
            }
            if (product?.merchandisingAssociations) {
              product.merchandisingAssociations.forEach(
                (association: IMerchAssociations) => {
                  if (association?.seo?.href) {
                    association.seo.href = PRODUCT_PATH + association.seo.href;
                  }
                }
              );
            }
            return product;
          }
        );
      }

      return productDetailsResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method fetchProductsByPartNumbers Fetch products from the backend based on their partNumbers.
   *
   * @param IProductsByPartNumbersResponse
   */
  async fetchProductsByPartNumbers({
    partNumbers,
    storeID,
    cancelToken,
  }: IFetchProduct): Promise<IProductsByPartNumbersResponse> {
    try {
      const { FETCH_PRODUCT_BY_PART_NUMBERS } = ProductServiceConstants;

      const { PRODUCT_PATH } = SeoConstants;

      const productIds = `${qs.stringify(
        { partNumber: partNumbers },
        {
          indices: false,
        }
      )}`;

      const userContractId = sessionStorage.getItem(USER_CONTRACT);

      const queryParams = {
        ...{ ...(userContractId && { contractId: userContractId }) },
      };

      const productsByPartNumbersRequest: AxiosRequestConfig = {
        url: FETCH_PRODUCT_BY_PART_NUMBERS(productIds, storeID),
        params: queryParams,
        cancelToken,
      };

      const productsByPartNumbersResponse: IProductsByPartNumbersResponse =
        validateIProductsByPartNumbersResponse(
          await makeRequest(productsByPartNumbersRequest)
        );

      if (productsByPartNumbersResponse.contents) {
        productsByPartNumbersResponse.contents =
          productsByPartNumbersResponse.contents.map(
            (product: IContentsItem) => {
              if (product.seo?.href) {
                product.seo.href = PRODUCT_PATH + product.seo.href;
              }
              if (product?.merchandisingAssociations) {
                product.merchandisingAssociations.forEach(
                  (association: IMerchAssociations) => {
                    if (association?.seo?.href) {
                      association.seo.href =
                        PRODUCT_PATH + association.seo.href;
                    }
                  }
                );
              }
              return product;
            }
          );
      }

      return productsByPartNumbersResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method fetchCatalogEntryProducts Fetches the catalog entry products from backend.
   *
   * @param ICatalogEntryProduct
   * @returns IProductsByPartNumbersResponse
   */
  async fetchCatalogEntryProducts({
    parentCatalogEntryID,
    storeID,
  }: ICatalogEntryProduct): Promise<IProductsByPartNumbersResponse> {
    try {
      const { CATALOG_ENTRY_PRODUCTS } = ProductServiceConstants;

      const { PRODUCT_PATH } = SeoConstants;

      const userContractId = sessionStorage.getItem(USER_CONTRACT);

      const queryParams = {
        ...{ ...(userContractId && { contractId: userContractId }) },
      };

      const catalogEntryProductsRequest: AxiosRequestConfig = {
        url: CATALOG_ENTRY_PRODUCTS(storeID, parentCatalogEntryID),
        params: queryParams,
      };

      const catalogEntryProductsResponse: IProductsByPartNumbersResponse =
        validateIProductsByPartNumbersResponse(
          await makeRequest(catalogEntryProductsRequest)
        );

      if (catalogEntryProductsResponse.contents) {
        catalogEntryProductsResponse.contents =
          catalogEntryProductsResponse.contents.map(
            (product: IContentsItem) => {
              if (product.seo?.href) {
                product.seo.href = PRODUCT_PATH + product.seo.href;
              }
              if (product?.merchandisingAssociations) {
                product.merchandisingAssociations.forEach(
                  (association: IMerchAssociations) => {
                    if (association?.seo?.href) {
                      association.seo.href =
                        PRODUCT_PATH + association.seo.href;
                    }
                  }
                );
              }
              return product;
            }
          );
      }

      return catalogEntryProductsResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method getShippingInfo Fetches the shipping information for a specific product.
   *
   * @param IShippingInfoRequest
   */
  async getShippingInfo({
    partNumber,
    physicalStoreId,
    storeID,
  }: IShippingInfoRequest): Promise<IShippingInfoResponse> {
    try {
      const { GET_SHIPPING_INFO } = ProductServiceConstants;

      const shippingInfoRequest: AxiosRequestConfig = {
        url: GET_SHIPPING_INFO(
          storeID,
          physicalStoreId,
          partNumber ? partNumber : ''
        ),
      };

      const shippingInfoResponse: IShippingInfoResponse = await makeRequest(
        shippingInfoRequest
      );

      return shippingInfoResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method fetchInventoryInfo Fetches the inventory info for the given partNumbers.
   *
   * @param IInventoryInfoRequest
   * @returns IShippingInfoResponse
   */
  async fetchInventoryInfo({
    partNumbers,
    physicalStoreId,
    storeID,
    cancelToken,
  }: IInventoryInfoRequest): Promise<IShippingInfoResponse> {
    try {
      const { GET_INVENTORY_INFO } = ProductServiceConstants;

      const inventoyInfoRequest: AxiosRequestConfig = {
        url: GET_INVENTORY_INFO(storeID),
        method: 'POST',
        cancelToken,
        data: {
          partNumbers,
          physicalStoreId,
        },
      };

      const inventoryInfoResponse: IShippingInfoResponse = await makeRequest(
        inventoyInfoRequest
      );
      
      return inventoryInfoResponse;
    } catch (e) {
      throw e;
    }
  },

  async fetchCategoryByIdentifier({
    storeID,
    identifier,
  }: IFetchCategoryByIdentifier): Promise<any> {
    try {
      const { FETCH_CATEGORY_BY_IDENTIFIER } = ProductServiceConstants;

      const fetchCategoryByIdentifier: AxiosRequestConfig = {
        url: FETCH_CATEGORY_BY_IDENTIFIER(storeID, identifier),
        method: 'GET',
      };

      const fetchCategoryByIdentifierResponse = await makeRequest(
        fetchCategoryByIdentifier
      );

      return fetchCategoryByIdentifierResponse;
    } catch (e) {
      throw e;
    }
  },
};

export { productsService };
