
/**
 * Simple div container to hold adobe custom code snippets.
 * @returns empty div set to hidden.
 */
const AdobeCustomCode: React.FC = () => {

    const divId = "adobe-custom-code";

    return (
        <div id={divId} aria-hidden="true"></div>
    );
};

export { AdobeCustomCode }