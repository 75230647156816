/**
 * @enum StockStatus
 */
export enum StockStatus {
  InStock = 'InStock',
  Backordered = 'Backordered',
  NonStock = 'NonStock',
  NotAvailable = 'NotAvailable',
}

/**
 * @enum ShippingType
 */
export enum ShippingType {
  STANDARD_GROUND_SHIPPING = 'Standard Ground Shipping',
  STANDARD_GROUND_FACTORY_SHIPPING = 'Standard Ground Factory Shipping',
  FREE_STANDARD_GROUND_SHIPPING = 'Free Standard Ground Shipping',
  FREE_STANDARD_GROUND_FACTORY_SHIPPING = 'Free Standard Ground Factory Shipping',
  STANDARD_TRUCK_SHIPPING = 'Standard Truck Shipping',
  STANDARD_TRUCK_FACTORY_SHIPPING = 'Standard Truck Factory Shipping',
  FREE_TRUCK_SHIPPING = 'Free Truck Shipping',
  FREE_TRUCK_FACTORY_SHIPPING = 'Free Truck Factory Shipping',
  US_MAIL = 'U.S. Mail (Physical Gift Cards)',
  ELECTRONIC = 'Electronic',
  ELECTRONIC_FULFILLMENT_ADVANTAGE = 'Electronic Fulfillment, Advantage',
  ELECTRONIC_FULFILLMENT_WARRANTY = 'Electronic Fulfillment, Warranty',
  ELECTRONIC_FULFILLMENT_EGIFTS = 'Electronic Fulfillment, E-Gift Cards',
  PREORDER = 'Preorder',
  NOT_AVAILABLE = 'Not Available (Sold Only in Stores)',
  SOLD_OUT_NO_LONGER_AVAILABLE = 'Sold Out / No longer available',
}

/**
 * @enum PricingScenario
 */
export enum PricingScenario {
  OnSale,
  NotOnSale,
}

/**
 * @enum LineType
 */
export enum LineType {
  S = 'S',
  S2 = 'S2',
  D = 'D',
  D1 = 'D1',
  NS = 'NS',
}

/**
 * @enum ShipMethod
 */
export enum ShipMethod {
  Truck = 'Truck',
  USPS = 'USPS',
  Electronic = 'Electronic',
}

/**
 * @enum StockStatusAttribute
 */
export enum StockStatusAttribute {
  S = 'S',
  J = 'J',
  U = 'U',
  Z = 'Z',
  O = 'O',
}

/**
 * @enum SoldAtStores
 */
export enum SoldAtStores {
  SoldAtStores = 'SoldAtStores',
  NotSoldAtStores = 'NotSoldAtStores',
  NotSoldAtMyStore = 'NotSoldAtMyStore',
}

/**
 * @enum StoreOnly
 */
export enum StoreOnly {
  StoreOnly = 'StoreOnly',
  NotStoreOnly = 'NotStoreOnly',
}

/**
 * @enum StoreStocked
 */
export enum StoreStocked {
  StoreStocked = 'StoreStocked',
  NotStoreStocked = 'NotStoreStocked',
}

/**
 * @enum GlobalBopis
 */
export enum GlobalBopis {
  GlobalBopis = 'GlobalBopis',
  NotGlobalBopis = 'NotGlobalBopis',
}

/**
 * @enum StoreBopis
 */
export enum StoreBopis {
  StoreBopis = 'StoreBopis',
  NotStoreBopis = 'NotStoreBopis',
}

/**
 * @enum StoreStatus
 */
export enum StoreStatus {
  Open = 'Open',
  Close = 'Close',
}

/**
 * @enum StoreInventory
 */
export enum StoreInventory {
  GreaterThanZero = '>0',
  LesserThanEqualToZero = '<=0',
  NA = 'N/A',
  UNKNOWN = 'UNKNOWN',
}

/**
 * @enum AnchorStore
 */
export enum AnchorStore {
  Selected = 'Selected',
  NotSelected = 'NotSelected',
}

export enum PlpPageView {
  LIST = 'list',
  GRID = 'grid',
}
