import { AxiosError } from 'axios';
import { differenceInYears, format, intervalToDuration } from 'date-fns';
import { decode } from 'html-entities';
import parse from 'html-react-parser';
import qs from 'querystring';
import {
  EncodedQuery,
  decodeDelimitedArray,
  decodeQueryParams,
  encodeDelimitedArray,
} from 'use-query-params';
import { uuid } from 'uuidv4';
import { PrimaryFlag } from '../_foundation/apis/auth/account/IAccountAddress';
import {
  CONTINUE_SHOPPING_LINKS,
  MY_STORE_COOKIE,
  NEAR_BY_STORES,
  RECENTLY_VISITED_LINKS,
  REGION_AD,
} from '../_foundation/constants/cookie';
import { UtagPageType } from '../_foundation/enum/Tealium/Tealium';
import { UserType } from '../_foundation/enum/User/UserType';
import { IContactInformation } from '../_foundation/interface/Authentication/IAuth';
import { IBreadcrumbLink } from '../_foundation/interface/Breadcrumb/IBreadcrumb';
import { Adjustment } from '../_foundation/interface/Cart/IOrder';
import { ICategoryData } from '../_foundation/interface/Category/ICategoryCardData';
import { INteAlertMessage } from '../_foundation/interface/NteAlert/INteAlert';
import { IBreadCrumbTrailEntryView } from '../_foundation/interface/ProductList/IProductList';
import { ICategoryChildren } from '../_foundation/interface/Responses/ICategoriesResponse';
import IEspotResponse from '../_foundation/interface/Responses/IEspotResponse';
import { ISeoState } from '../_foundation/interface/Seo/ISeo';
import {
  IFsdData,
  MyStoreCookie,
  PhysicalStoreDetails,
  StoreAttribute,
} from '../_foundation/interface/StoreLocator/IStoreLocator';
import { SelectOption } from '../components/Forms/NteSelect/NteSelect';
import { IProductVideos } from '../components/PDP/MediaModalVideos';
import {
  AddressType,
  IAddressForm,
} from '../components/Widgets/AddressForm/IAddressForm';
import { StoreMenu } from '../components/Widgets/AppBar/PrimaryMenuItems/NavBarMenuItem/interface/StoreMenuInterface';
import {
  IOrderDetail,
  IOrderItem,
} from '../components/Widgets/Orders/OrderDetail/IOrderDetail';
import {
  RecentlyVisitedLinks,
  VisitedLink,
} from '../components/Widgets/ShoppingCart/ContinueShoppingLinks/IContinueShoppingLinks';
import i18n from '../i18n';

/**
 * This method is responsible for formatting number as
 * a proper currency format (US dollars).
 *
 * Example: 3456 => $3,456
 */
const { format: formatToDollars } = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

/**
 * @method setCookie is responsible for setting cookie in the brower.
 *
 * @param key Send the key value for the cookie.
 * @param value Send the value/object to be stored for the given cookie key.
 * @param expires Send the expiration value for the cookie.
 */
const setCookie = (key: string, value: any, expires: any = ''): void => {
  document.cookie = `${key}=${JSON.stringify(value)}; expires=${expires}`;
};

/**
 * @method getCookie is responsible for retriving the cookie value
 * for the specified key from the brower cookie.
 *
 * @param key Send the key value of the cookie to be retrieved.
 * @returns The cookie value for the specified key.
 */
const getCookie = (key: string): any => {
  if (document.cookie.length > 0) {
    let cookieStart = document.cookie.indexOf(key + '=');
    if (cookieStart !== -1) {
      cookieStart = cookieStart + key.length + 1;
      let cookieEnd = document.cookie.indexOf(';', cookieStart);
      if (cookieEnd === -1) {
        cookieEnd = document.cookie.length;
      }
      return unescape(document.cookie.substring(cookieStart, cookieEnd));
    }
  }

  return '';
};

/**
 * @method deleteCookie is responsible for removing a cookie
 * value for the specified key.
 *
 * @param key Send the key of the cookie to be deleted.
 */
const deleteCookie = (key: string) => {
  document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

/**
 * @method allowGeoCoordinates is responsible for checking whether the browser supports the
 * geoCoordinates and fetches the coordinates based on the success and error callback logic
 * passed to it.
 *
 * @param positionCallBack is responsible for fetching the current position's geoCoordinates.
 * @param positionErrorCallback is responsible for catching the errors when the user denies permission.
 * @param positionOptions Optional callback options.
 * @returns Returns the alert message when the browser doesn't support the geoCoordinates.
 */
const allowGeoCoordinates = (
  positionCallBack: PositionCallback,
  positionErrorCallback: PositionErrorCallback,
  positionOptions?: PositionOptions
) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      positionCallBack,
      positionErrorCallback,
      positionOptions
    );
  }

  return `Your browser doesn't support geolocation`;
};

/**
 * @method hasKey is responsible for checking whether the
 * given item is available in an object.
 */
function hasKey<O>(obj: O, key: PropertyKey): key is keyof O {
  return key in obj;
}

/**
 * @method enterKeyPressHandler executes a callback on enter
 * key press.
 *
 * @param e KeyPress event of the current element.
 * @param clickHandler Pass in the handler callback that needs to be executed on
 * enter key press.
 */
const enterKeyPressHandler = (e: any, clickHandler?: () => void): void => {
  if (e.charCode === 13 || e.keyCode === 13) {
    if (clickHandler) {
      clickHandler();
    }
  }
};

/**
 * @method generateAlphabets Generates uppercase alphabets from A - Z
 * and returns the results in an array.
 *
 * @returns Array of alphabets
 */
const generateAlphabets = () => {
  const alphabets = [];
  const start = 'A'.charCodeAt(0);
  const last = 'Z'.charCodeAt(0);

  for (let i = start; i <= last; ++i) {
    alphabets.push(String.fromCharCode(i));
  }

  return alphabets;
};

/**
 * @method generateNumbers Generates numbers sequentially between the
 * give start and end number.
 *
 * @param startNumber Initial Number of the sequence
 * @param endNumber Final Number of the sequence
 * @returns Array of numbers.
 */
const generateNumbers = (startNumber: number, endNumber: number) => {
  const numbers = [];

  for (let i = startNumber; i <= endNumber; ++i) {
    numbers.push(i);
  }

  return numbers;
};

/**
 * @method formatToTitleCase converts given string to title case.
 *
 * @param text to be formatted to title case.
 * @returns Text formatted to title case.
 */
const formatToTitleCase = (text: string): string => {
  let str = text.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return decode(str.join(' '));
};

/**
 * @method checkSeeAllMenuItem checks whether the given item contains "see all "
 *
 * @param menuItem Current menu item.
 * @returns Returns a bool
 */
const checkSeeAllMenuItem = (menuItem: string): boolean =>
  menuItem.indexOf('see all ') !== -1;

/**
 * @method autoCompleteFocusHandler
 * Handles text field focus when autofilled, resolving the cursor issue in bug-134585.
 * Reassigning the field's current value to itself restores the missing text cursor.
 */
const autoCompleteFocusHandler = (id: any) => {
  const inputElement = document.getElementById(id) as HTMLInputElement;
  if (inputElement?.value) {
    inputElement.value = inputElement.value;
  }
};

/**
 * @method stripItemCount Strips item count from menu item.
 * Ex: Automotive(8) -> Automotive.
 *
 * @param item Current menu item.
 * @returns returns item without count at end.
 */
const stripSeeAllItemCount = (item: string): string => {
  let updatedItem: string = item
    .replace('see all', '')
    .replace('+', '')
    .trimStart()
    .trim();

  if (updatedItem.includes(' (')) {
    const stripped: string[] = updatedItem.split(' (');
    updatedItem = stripped.splice(0, stripped.length - 1).join(' ');
    return updatedItem;
  } else return updatedItem;
};

/**
 * @method getCurrentDay Get the current day number.
 *
 * @returns Current Day number.
 */
const getCurrentDay = (): number => new Date().getDay();

/**
 * @method formatTime Extracts time from date object and formats it
 * to either 12hrs or 24hrs.
 *
 * @param date Pass in the date object.
 * @param isHour12 Flag that determines 12hrs or 24hrs
 * @returns Formatted time from date object.
 */
const formatTime = (date: Date, isHour12: boolean, showMin?: boolean): string =>
  date.toLocaleString('en-US', {
    hour: 'numeric',
    ...{ ...(showMin && { minute: '2-digit' }) },
    hour12: isHour12,
  });

const formatBreadCrumbLink = (linkText: string): string => {
  return decodeURIComponent(
    linkText
      .replace(/[^a-zA-Z0-9 ]/g, ' ')
      .split(' ')
      .map((str) => (str.length > 0 ? str[0].toUpperCase() + str.slice(1) : ''))
      .join(' ')
  );
};

/**
 * @method formatUrlToTitleCase converts the url string to
 * title case ex: air-tools-compressors => Air Tools Compressors.
 *
 * @param text to be formated to Title Case
 * @returns The formatted string.
 */
const formatUrlToTitleCase = (text: string): string =>
  formatToTitleCase(text.replace(/-/g, ' '));

/**
 * @method formatSiteMapHeading converts the string to
 * case ex: Air_Tools-Compressors => Air Tools Compressors.
 *
 * @param text to be formated
 * @returns The formatted string.
 */
const formatSiteMapHeading = (text: string | undefined): string => {
  if (text) {
    return text.replace(/-/g, ' ').replace(/_/g, ' ');
  }
  return '';
};

/**
 * @method constructBrandsImgName Constructs and returns brand image name from the give text.
 *
 * @param text to be converted to brands img name.
 * @returns Constructed brands image name.
 */
const constructBrandsImgName = (text: string): string =>
  `${text.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')}_lg.gif`;

/**
 * @method extractEspotHtml Extract the espot html content from the espot response.
 *
 * @param espot Send the espot response object.
 * @returns Returns the marketingText.
 */
const extractEspotHtml = (espot: IEspotResponse): string => {
  const { MarketingSpotData } = espot;

  if (MarketingSpotData[0]?.baseMarketingSpotActivityData) {
    const { marketingContentDescription } =
      MarketingSpotData[0]?.baseMarketingSpotActivityData[0];

    const { marketingText } = marketingContentDescription[0];

    if (marketingText) {
      return marketingText;
    }
  }

  return '';
};

/**
 * @method isStoreAvailable Checkd whether a store is available in the cookie.
 *
 * @returns boolean flag that determines whether the store is available in the cookie or not.
 */
const isStoreAvailable = (): boolean => {
  const myStoreCookie = sessionStorage.getItem(MY_STORE_COOKIE);

  if (myStoreCookie && JSON.parse(myStoreCookie).storeName) {
    const parsedStoreCookie: MyStoreCookie = JSON.parse(myStoreCookie);

    return Boolean(parsedStoreCookie.storeName);
  } else {
    return false;
  }
};

/**
 * @method getStoreName fetches the city of the selected store
 *
 * @returns store name of the selected store
 */
const getStoreName = (): string => {
  const storeCookie = sessionStorage.getItem(MY_STORE_COOKIE);

  if (storeCookie && JSON.parse(storeCookie).storeName) {
    return JSON.parse(storeCookie).storeName;
  }

  return '';
};

/**
 * @method setStoreSession Sets the store details in the session.
 *
 * @param storeDetails
 */
const setStoreSession = (storeDetails: PhysicalStoreDetails): void => {
  const myStoreCookie = sessionStorage.getItem(MY_STORE_COOKIE);

  const { ...myStoreDetails } = storeDetails;

  if (myStoreCookie && storeDetails) {
    const parsedStoreCookie = JSON.parse(myStoreCookie);

    const myStore: MyStoreCookie = {
      ...parsedStoreCookie,
      ...myStoreDetails,
      storeId: storeDetails.uniqueID,
    };

    sessionStorage.setItem(MY_STORE_COOKIE, JSON.stringify(myStore));
  } else {
    const myStore: MyStoreCookie = {
      ...myStoreDetails,
      storeId: storeDetails.uniqueID,
    };

    sessionStorage.setItem(MY_STORE_COOKIE, JSON.stringify(myStore));
  }

  /**
   * Responsible to get the ADregion from the anchor store
   */
  const regionAd: StoreAttribute[] =
    storeDetails &&
    myStoreDetails.Attribute.filter(
      (attribute) => attribute.name === 'adRegion'
    );

  if (regionAd && regionAd.length > 0) {
    sessionStorage.setItem(REGION_AD, regionAd[0].value.toUpperCase());
  } else {
    sessionStorage.removeItem(REGION_AD);
  }
};

/**
 * @method clearStoreSession removes the MY_STORE_COOKIE value from session storage.
 */
const clearStoreSession = (): void => {
  sessionStorage.removeItem(NEAR_BY_STORES);
  sessionStorage.removeItem(REGION_AD);

  const myStoreCookie = sessionStorage.getItem(MY_STORE_COOKIE);

  if (myStoreCookie) {
    const parsedStoreCookie = JSON.parse(myStoreCookie);

    const updatedStoreCookie = {
      geoCoordinates: {
        lat: parsedStoreCookie.geoCoordinates.lat,
        lng: parsedStoreCookie.geoCoordinates.lng,
      },
    };

    sessionStorage.setItem(MY_STORE_COOKIE, JSON.stringify(updatedStoreCookie));
  }
};

/**
 * @method formatToUrlString formats the string to url case.
 * ex: Air Tools + Compressors => air-tools-compressors
 *
 * @param str Send in the string that needs to be formatted.
 * @returns The formatted string.
 */
const formatToUrlString = (str: string): string =>
  str
    .replace(/[^\w\s]/gi, '')
    .replace(/ /g, '-')
    .replace(/--/g, '-')
    .toLowerCase();

/**
 * @method formatSeoUrlToString
 * Formats the seoHref string to url.
 * Ex: "/air-tools-compressor" => "Air Tools Compressor"
 */
const formatSeoUrlToString = (seoHref: string) =>
  formatToTitleCase(seoHref?.replace(/\//g, '').replace(/-/g, ' ')).replace(
    / /g,
    ''
  );

/**
 * @method addStaticQueryParams Appends static query params in the url.
 * This method can only be used to add query params only in case of
 * preventing the page rerender or the page reload.
 *
 * @param params Query params that needs to be appended in the url
 */
const addStaticQueryParams = (params: URLSearchParams): void => {
  const queryParam = Boolean(params.toString()) ? '?' : '';

  if (window.history.replaceState) {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      queryParam +
      params.toString();

    window.history.replaceState({ path: newurl }, '', newurl);
  }
};

/**
 * @method checkTruthy
 * Responsible for handling string based bools
 */
const checkTruthy = (checkBool?: string) => {
  if (checkBool) {
    const result =
      checkBool.toLowerCase() === 'true' ||
        checkBool.toLowerCase() === 'yes' ||
        checkBool.toLowerCase() === 'y'
        ? true
        : false;

    return result;
  }

  return false;
};

/**
 * @method getEnumKeyByValue Extracts the enum key based on the enum value.
 */
function getEnumKeyByValue(myEnum: any, enumValue: number | string): string {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : '';
}

/*
 * @component alertMessageId Randomly generated alert message id.
 */
const alertMessageId = (): string => uuid();

/**
 * @method formatFacetPrice Formats the facet label from ({999 TO 1998]) to $999 - $1998
 *
 * @param IFormattedPrices
 */
const formatFacetPrice = (priceLabel: string): string => {
  const prices = priceLabel
    .replace(/[{()}]/g, '')
    .replace(/]/g, '')
    .replace(/ /g, '')
    .split('TO');

  let fromPrice =
    prices[0] === '*'
      ? `Below ${formatPrice(prices[1])}`
      : `${formatPrice(prices[0])}`;

  const toPrice =
    prices[1] === '*'
      ? `Over ${formatPrice(prices[0])}`
      : `${formatPrice(prices[1])}`;

  if (fromPrice && toPrice) {
    if (prices[0] === '*') {
      return fromPrice;
    } else if (prices[1] === '*') {
      return toPrice;
    }
    return `${fromPrice} - ${toPrice}`;
  }

  return '';
};

/**
 * @interface UtagData
 */
export interface IUtagData {
  pageType: string | undefined;
  pageName: string;
  pageSiteSection: string;
}

/**
 * @method parseBreadCrumbLinksToUtagData Formats breadcrumb links for Utag Data.
 *
 * @param anchorLinks
 */
const parseBreadCrumbLinksToUtagData = (
  anchorLinks: IBreadcrumbLink[]
): IUtagData => {
  /* Construct Page Type and Page Name from breadcrumb links to be sent to Tealium analytics */
  const pageType = anchorLinks[1]?.displayText.toLowerCase();
  let pageName = '';
  let pageSiteSection = '';
  anchorLinks.forEach((link, index) => {
    if (index === 1) {
      pageName += `${link.displayText.toLowerCase()}`;
      pageSiteSection += `${link.displayText.toLowerCase()}`;
    } else if (index > 1) {
      /* Send only the top 2 sections as Page Site section */
      if (index < 3) {
        pageSiteSection += ` | ${link.displayText.toLowerCase()}`;
      }
      pageName += ` | ${link.displayText.toLowerCase()}`;
    }
  });

  if (anchorLinks.length === 2) {
    pageName += ' | all';
    pageSiteSection += ' | all';
  }

  return {
    pageName,
    pageType,
    pageSiteSection,
  };
};

/**
 * @method parseUtagCategoryBrandData Formats breadcrumb links to Category/Brand Utag Data.
 *
 * @param links
 */
const parseLinksToUtagCategoryBrandData = (
  breadcrumbLinks: IBreadCrumbTrailEntryView[]
): IUtagData => {
  let pageType = breadcrumbLinks[0]?.label?.toLowerCase();
  let pageName = '';
  let pageSiteSection = '';

  breadcrumbLinks.forEach((link, index) => {
    if (index === 0) {
      pageName += `${link?.label?.toLowerCase()}`;
      pageSiteSection += `${link?.label?.toLowerCase()}`;
    } else {
      /* Send only the top 2 sections as Page Site section */
      if (index < 2) {
        pageSiteSection += ` | ${link?.label?.toLowerCase()}`;
      }
      pageName += ` | ${link?.label?.toLowerCase()}`;
    }
  });

  if (breadcrumbLinks.length === 1) {
    pageName += ' | all';
    pageSiteSection += ' | all';
  }

  return {
    pageName,
    pageType,
    pageSiteSection,
  };
};

/**
 * @method parseLinkstoUtagPageData Formats breadcrumb links to Plp Utag Data.
 *
 * @param links
 */
const parseLinkstoUtagPageData = (
  breadcrumbLinks: IBreadCrumbTrailEntryView[],
  type: UtagPageType,
  itemNumber?: string
): IUtagData => {
  const pageTypes = {
    [UtagPageType.category]: 'category',
    [UtagPageType.plp]: 'plp',
    [UtagPageType.pdp]: 'pdp',
  };

  let pageType = pageTypes[type];
  let pageName = pageTypes[type];
  let pageSiteSection = pageTypes[type];

  breadcrumbLinks.forEach((link, index) => {
    if (breadcrumbLinks[0].label === 'Brands') {
      if (index === 0) {
        pageSiteSection += ` | ${link?.label?.toLowerCase()}`;

        pageType += ` | ${link?.label?.toLowerCase()}`;
      }

      pageName += ` | ${link?.label?.toLowerCase()}`;
    } else {
      if (index > 0) {
        /* Send only the top 2 sections as Page Site section */
        if (index < 2) {
          pageSiteSection += ` | ${link?.label?.toLowerCase()}`;
        }
        pageName += ` | ${link?.label?.toLowerCase()}`;
      }
    }
  });

  if (type === UtagPageType.pdp && itemNumber) {
    pageName += ` | item ${itemNumber.toLowerCase()}`;
  }

  return {
    pageName,
    pageType,
    pageSiteSection,
  };
};

/**
 * @method formatSearchTerm
 * Responsible to formats the search term entered in the search bar before sending it to backend.
 *
 * if the search term has '-', only one occurence and after the ('-'), if the search term has only four digit (only number)
 *
 * ex: 2020-4444, it need to search for 2020
 *
 * if the above condition fails we need send the entire search term with ('-')
 *
 * ex: 48-1199-1862, 2646-21CT, 2691-22, 48-11-1862
 *
 */
const formatSearchTerm = (searchTerm: string): string => {
  let formattedSearchTerm = searchTerm.trim();

  formattedSearchTerm = formattedSearchTerm.split('-')[1];

  if (
    formattedSearchTerm &&
    formattedSearchTerm?.length === 4 &&
    Number(formattedSearchTerm) &&
    searchTerm.trim().split('-').length === 2
  ) {
    return searchTerm.trim().split('-')[0];
  } else {
    return searchTerm.trim();
  }
};

/**
 * @method formatQueryParams Provides add and remove methods to add or remove
 * delimiter string in the query params.
 *
 * @param delimiter Delimiter string.
 */
const formatQueryParams = (delimiter: string) => {
  return {
    add: (array: string[] | null | undefined) =>
      encodeDelimitedArray(array, delimiter),

    remove: (arrayStr: string | string[] | null | undefined) =>
      decodeDelimitedArray(arrayStr, delimiter),
  };
};

/**
 * @method formatOrderPrice Formats the price shown on order history
 *
 * @param orderItm.
 */
const formatOrderPrice = (orderItem: IOrderItem) => {
  const { itemPrice, itemQty, itemTotalPrice } = orderItem;

  const roundedItemPrice = formatPrice(itemPrice);

  const roundedTotalPrice = formatPrice(itemTotalPrice);

  return Number(itemQty) > 1
    ? `${roundedTotalPrice} (${itemQty} at ${roundedItemPrice})`
    : `${roundedItemPrice}`;
};

/**
 * @method getOrderFilterOptions Formats the price shown on order history
 *
 */
const getOrderFilterOptions = (
  defaultFilterOption: string
): Array<SelectOption> => {
  const currentYear = new Date().getFullYear();

  const yearsToBeMapped = [0, 1, 2, 3, 4, 5];

  const filterOptions = yearsToBeMapped.map((subtractYear) => {
    return {
      value: `${currentYear - subtractYear}`,
      label: `${currentYear - subtractYear}`,
    };
  });

  filterOptions.unshift({
    value: defaultFilterOption,
    label: defaultFilterOption,
  });

  return filterOptions;
};

/**
 * @method getTrackOrderUrl returns the track order url for a given order.
 *
 */
const getTrackOrderUrl = (orderDetail: IOrderDetail): string => {
  const trackOrderUrl = orderDetail.trackingUrl;

  return trackOrderUrl;
};

/**
 * @method formatGiftCardNumber Adds space between every four characters.
 *
 * Example:
 * "3456345326578" => 3456 3453 2657
 */
const formatGiftCardNumber = (cardNumber?: string): string => {
  const REGEX_PATTERN = /.{1,4}/g;

  const number = cardNumber?.match(REGEX_PATTERN);

  const formattedCardNumber = number?.join(' ');

  return `${formattedCardNumber}`;
};

/**
 * @method formatPrice Converts price in string to number and formats it.
 *
 * Example:
 * "3456.34532" => $3456.34
 * "233454.234" => $23,3454.23
 */
const formatPrice = (price: string): string => {
  const finalPrice = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(+price);

  if (finalPrice.length === 9) {
    return finalPrice.replace(',', '');
  }

  return `${finalPrice}`;
};

/**
 * @method formatPriceWihoutSymbol converts price into string
 * Example "3456.34532" => 3456.34
 */
const formatPriceWihoutSymbol = (price: string): string => {
  const pricewithDecimal = Number(price).toFixed(2);

  return pricewithDecimal;
};

/**
 * @method calculateTax
 * @param totalSalesTax
 * @param totalShippingTax
 */
const calculateTax = (
  totalSalesTax: string,
  totalShippingTax: string
): string => {
  const totalTax = Number(totalSalesTax) + Number(totalShippingTax);
  return formatPrice(totalTax.toString());
};

/**
 * @method calculateGrandTotal returns the grand total
 * to be displayed in the cart and delivery page.
 *
 * @param grandTotal
 * @param totalSalesTax
 * @param totalShippingTax
 * @returns
 */
const calculateGrandTotal = (
  grandTotal: string,
  totalSalesTax: string,
  totalShippingTax: string
): string => {
  return (
    Number(grandTotal) -
    (Number(totalSalesTax) + Number(totalShippingTax))
  ).toString();
};

/**
 *
 * @method updateRviData updates the visited link to the RVI.
 */
const updateRviData = (visitedLink: VisitedLink) => {
  const rviData: RecentlyVisitedLinks = getLocalStorage(RECENTLY_VISITED_LINKS);

  if (rviData) {
    const isProductAlreadyPresent = rviData.visitedLinks.some(
      ({ productPartNumber }) =>
        visitedLink.productPartNumber === productPartNumber
    );

    if (!isProductAlreadyPresent) {
      rviData.visitedLinks.push(visitedLink);

      if (rviData.visitedLinks.length >= 12) {
        rviData.visitedLinks = rviData.visitedLinks.slice(-12);
        setLocalStorage(RECENTLY_VISITED_LINKS, rviData);
      } else {
        setLocalStorage(RECENTLY_VISITED_LINKS, rviData);
      }
    } else if (rviData.visitedLinks.length >= 12) {
      rviData.visitedLinks = rviData.visitedLinks.slice(-12);
      setLocalStorage(RECENTLY_VISITED_LINKS, rviData);
    }
  } else {
    const newRviData: RecentlyVisitedLinks = {
      visitedLinks: [visitedLink],
    };

    setLocalStorage(RECENTLY_VISITED_LINKS, newRviData);
  }
};

/**
 *
 * @method updateContinueShopping updates the visited link to the ContinueShopping.
 */
const updateContinueShopping = (visitedLink: VisitedLink) => {
  const ContinueShopping: RecentlyVisitedLinks = getLocalStorage(
    CONTINUE_SHOPPING_LINKS
  );

  if (ContinueShopping) {
    const isProductAlreadyPresent = ContinueShopping.visitedLinks.some(
      ({ productPartNumber }) =>
        visitedLink.productPartNumber === productPartNumber
    );

    if (!isProductAlreadyPresent) {
      ContinueShopping.visitedLinks.push(visitedLink);

      if (ContinueShopping.visitedLinks.length >= 12) {
        ContinueShopping.visitedLinks =
          ContinueShopping.visitedLinks.slice(-12);
        setLocalStorage(CONTINUE_SHOPPING_LINKS, ContinueShopping);
      } else {
        setLocalStorage(CONTINUE_SHOPPING_LINKS, ContinueShopping);
      }
    } else if (ContinueShopping.visitedLinks.length >= 12) {
      ContinueShopping.visitedLinks = ContinueShopping.visitedLinks.slice(-12);
      setLocalStorage(CONTINUE_SHOPPING_LINKS, ContinueShopping);
    }
  } else {
    const newContinueShoppingLink: RecentlyVisitedLinks = {
      visitedLinks: [visitedLink],
    };

    setLocalStorage(CONTINUE_SHOPPING_LINKS, newContinueShoppingLink);
  }
};

/**
 *
 * @method formatAddressDetails Formats address details from Address form to support address book.
 */
const formatAddressDetails = (
  addressDetails: Partial<IAddressForm>
): IContactInformation => {
  return {
    firstName: '',
    lastName: '',
    nickName: '',
    businessTitle: addressDetails.businessName,
    country: addressDetails.country || '',
    state: addressDetails.state || '',
    addressLine: [
      addressDetails.streetAddress || '',
      addressDetails.streetAddress2 || '',
    ],
    city: addressDetails.city || '',
    zipCode: addressDetails.zipCode || '',
    phone1: '',
    addressId: '',
    email1: '',
    addressType: AddressType.SHIPPING,
    primary: PrimaryFlag.true,
    xcont_lastUpdate: '',
    xcont_field2: addressDetails.deliveryType
      ? addressDetails.deliveryType
      : '',
  };
};

/**
 * @method genericErrorAlert Displays a generic alert message with error theme.
 */
const genericErrorAlert = (): INteAlertMessage => ({
  message: 'Something went wrong, please try again later.',
  messageId: alertMessageId(),
  severity: 'error',
});

/**
 * @method setLocalStorage Sets a value in the local storage.
 *
 * @param key Pass the key for which the values need to be saved.
 * @param value Value that needs to be saved.
 */
const setLocalStorage = (key: string, value: any): void =>
  localStorage.setItem(key, JSON.stringify(value));

/**
 * @method getLocalStorage Fetches value from local storage based on a given key.
 *
 * @param key Pass the key from which the values needs to be retrived
 * @returns Values retrived from the local storage.
 */
const getLocalStorage = (key: string) => {
  if (localStorage.getItem(key)) {
    const value = localStorage.getItem(key);

    try {
      if (value && Boolean(value)) {
        return JSON?.parse(value);
      }
    } catch (e) {
      console.error(e);

      return '';
    }
  }
};

/**
 * @method removeLocalStorage Removes a value from localstorage with respect to it's key.
 *
 * @param key Pass the key for which the value needs to be removed from local storage.
 */
const removeLocalStorage = (key: string) => localStorage.removeItem(key);

/*
 * @method createDebouncer creates a debouncer function that executes
 * function only after a delay and resets if it's fired within the time frame.
 */
const createDebouncer = (callback: any, delay: number) => {
  let prevTimeout: any;

  return async (...params: any[]) => {
    if (prevTimeout) {
      clearTimeout(prevTimeout);
    }

    prevTimeout = setTimeout(() => {
      return callback(...params);
    }, delay);
  };
};

/**
 * @method convertStrToNumber Converts the string value to an absolute number value.
 *
 * @param numberStr Pass in the number string that needs to be converted to number.
 * @returns Absolute number value.
 */
const convertStrToNumber = (numberStr: string): number =>
  Math.abs(Number(numberStr));

/**
 * @method getShippingDay will get the day from the date.
 *
 * @param date Pass in the  string that needs to be give the day.
 * @returns day from the date
 */
const getShippingDay = (date: string) => {
  return new Date(date!).toDateString().split(' ')[0];
};

/**
 * @method getShippingDate will get the date without the day.
 *
 * @param date Pass in the  string that needs to be give the date.
 * @returns date without the day
 */
const getShippingDate = (date: string) => {
  return new Date(date!).toDateString().split(' ').slice(1, 3).join(' ');
};

/**
 * @method NUMBERS will change the number to string value.
 *
 */

const NUMBERS: any = {
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
};

/**
 * @method generatePickupPersonNickName generates a nickname for the pickup person
 * @param firstName firstName provided by the user
 * @param lastName lastName provided by the user
 * @returns pickup person nickname as a string
 */
const generatePickupPersonNickName = (
  firstName: string,
  lastName: string
): string => {
  return `${firstName}_${lastName}_${format(new Date(), "yyyyMMdd'_'HHmmss")}`;
};

/**
 * @method getHighestDateRange generates a highest date object
 * @param data data is the array which contains array of objects
 * @returns object which contains highest date.
 */
const getHighestDateRange = (data: IFsdData[]) => {
  const highestDateObject = data.reduce((max, min) =>
    new Date(max.expDeliveryHigh) > new Date(min.expDeliveryHigh) ? max : min
  );
  return highestDateObject;
};

/**
 * @method getLowestDateRange generates a lowest date object
 * @param data data is the array which contains array of objects
 * @returns object which contains lowest date.
 */
const getLowestDateRange = (data: IFsdData[]) => {
  const lowestDateObject = data.reduce((max, min) =>
    new Date(max.expDeliveryHigh) < new Date(min.expDeliveryHigh) ? max : min
  );
  return lowestDateObject;
};

/**
 * @method formattedDateRange construct date range
 * @param data is an object which will be getting lineItem data
 * @returns string
 */
const formattedDateRange = (data: IFsdData) => {
  const time = 'T00:00:00'; // adding this to date while formatting to avoid wrong date range due to timezone
  if (data && data.expDeliveryHigh && data.expDeliveryLow) {
    if (data.expDeliveryHigh === data.expDeliveryLow) {
      const expDeliveryLowYear = greaterThan365(data.expDeliveryLow)
        ? `, ${new Date(data.expDeliveryLow).getFullYear()}`
        : '';

      return `${data?.expDeliveryLow &&
        `${format(
          new Date(data.expDeliveryLow + time),
          'eee, LLL d'
        )}${expDeliveryLowYear}`
        }`;
    } else {
      const expDeliveryHighYear = greaterThan365(data.expDeliveryHigh)
        ? `, ${new Date(data.expDeliveryHigh).getFullYear()}`
        : '';

      return `${data?.expDeliveryLow &&
        `${format(new Date(data.expDeliveryLow + time), 'eee, LLL d')}`
        } - ${data?.expDeliveryHigh &&
        `${format(
          new Date(data.expDeliveryHigh + time),
          'eee, LLL d'
        )}${expDeliveryHighYear}`
        }`;
    }
  }

  return '';
};

/**
 * @method sendTealiumData Sends the event data to tealium.
 *
 * @param trackedData Pass the data that needs to be sent by tealium.
 */
const sendTealiumData = (trackedData: any): void => {
  if (window.utag) {
    utag.link(trackedData);
  }
};

/**
 * @method convertTo12HFormat converts hours from a 24 hour format string to a 12 format number
 * @param hours is the 4-digit 24 hour format hour as a string
 * @returns the 12 hour format hour value as a number
 */
const convertTo12HFormat = (hours: string): number => {
  let convertedFormat = 0;
  if (hours.length > 1) {
    convertedFormat = Number(hours.substring(0, 2)) % 12 || 12;
  }
  return convertedFormat;
};

/**
 * @method getAMOrPM checks whether the hour value is AM or PM
 * @param hour the input hour as a string
 * @returns a string depending on whether the hour is AM or PM
 */
const getAMOrPM = (hour: string): string => {
  let AmOrPMValue = '';
  if (hour.length > 1) {
    AmOrPMValue = Number(hour.substring(0, 2)) < 12 ? 'am' : 'pm';
  }
  return AmOrPMValue;
};

/**
 * @method findCategoryById
 */

const findCategoryById = (
  categoriesArray: ICategoryData[],
  id: string
): string => {
  let result: string = '';
  const trimId = id.replace(/\//g, '');

  const searchItem = (categories: StoreMenu[]) => {
    for (let category of categories) {
      if (category.name) {
        if (category.id === trimId) {
          result = category.name;
          break;
        } else {
          if (category.children) {
            searchItem(category.children);
          }
        }
      }
    }
  };

  const getEachItem = (headerMenuData: ICategoryData[]) => {
    headerMenuData.forEach((item) => {
      if (item.name === 'Categories') {
        if (item.children) {
          searchItem(item.children);
        }
      } else {
        return;
      }
    });
  };

  getEachItem(categoriesArray);
  return result;
};

const removeDuplicatePromo = (adjustment: Adjustment[]) => {
  if (adjustment && adjustment.length > 0) {
    return adjustment.filter((promo) => !checkTruthy(promo.xadju_isPromoCode));
  } else {
    return [];
  }
};

/**
 * @method isNetworkError Returns bool flag based on the network error status codes.
 *
 * @param axiosError
 */
const isNetworkError = ({ response }: AxiosError): boolean =>
  response?.status === 300 ||
  response?.status === 400 ||
  response?.status === 500 ||
  response?.status === 503 ||
  response?.status === 404;

/**
 * @method constructESpotId constructs an espot id based on the current path
 * @param espotId
 * @param espotPrefix the tokenExternalValue or current path of the page
 * @returns constructed espot id as a string
 */
const constructESpotId = (espotId: string, espotPrefix: string): string => {
  return `${espotPrefix
    .replace(/[^\w\s]/gi, ' ')
    .replace(/\s+/g, '-')
    .toLowerCase()}_${espotId}`;
};

/**
 * @method isIos checks for the device from the window object
 * @returns returns true if device is iOS
 */
const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

// taken from https://web.dev/customize-install/
const isLaunchedAsPwa = window.matchMedia('(display-mode: standalone)').matches;

/**
 * @method customAxiosError Constructs custom axios error object for the error message and code.
 */
const customAxiosError = (
  errorMessage: string,
  errorCode: number,
  apiName: string = ''
): AxiosError => {
  return {
    config: {},
    isAxiosError: true,
    message: errorMessage,
    name: apiName,
    toJSON: () => {
      return {};
    },
    response: {
      config: {},
      headers: {},
      data: {
        errors: [
          {
            errorKey: errorMessage,
          },
        ],
      },
      status: errorCode,
      statusText: errorMessage,
    },
  };
};

/**
 * @method getRegionAdForAnchorStore
 * Responsible to get the region Ad for anchor store
 */
const getRegionAdForStore = (storeDetaials: PhysicalStoreDetails): string => {
  if (storeDetaials) {
    const regionAd = storeDetaials?.Attribute?.filter(
      (attribute) => attribute.name === 'adRegion'
    );

    if (regionAd.length > 0) {
      return regionAd[0].value.toUpperCase();
    } else {
      return '';
    }
  } else {
    return '';
  }
};

/**
 * @method getSessionStorage Fetches the value from the session storage for a given key.
 *
 * @param sessionKey Session item key for which the value to be retrived.
 */
const getSessionStorage = (sessionKey: string): any => {
  const sessionValues = sessionStorage.getItem(sessionKey);

  if (sessionValues) {
    return JSON.parse(sessionValues);
  } else {
    return '';
  }
};

/**
 * @method compareCategorySequence Compares objects in the categories menu array.
 */
const compareCategorySequence = (
  a: StoreMenu | ICategoryData,
  b: StoreMenu | ICategoryData
) => {
  if (a.sequence && b.sequence) {
    if (Number(a.sequence) > Number(b.sequence)) {
      return 1;
    }
    if (Number(a.sequence) < Number(b.sequence)) {
      return -1;
    }
    return 0;
  }

  return 0;
};

/**
 * @method checkMembership checks the user type of the user
 * @param userType
 * @returns boolean value for each membership type
 */
const checkMembership = (userType: UserType) => {
  const isNtePreferred = userType === UserType.NorthernPreferred;

  const isPlatinum = userType === UserType.Platinum;

  const isGreatPlains = userType === UserType.GreatPlains;

  const isAdvantage = userType === UserType.Advantage;

  const isMembership =
    isNtePreferred || isPlatinum || isGreatPlains || isAdvantage;

  return {
    isNtePreferred,
    isPlatinum,
    isGreatPlains,
    isAdvantage,
    isMembership,
  };
};

/**
 * Method to construct the page title for the page title meta tag
 * @param seoConfig
 * @param formattedPath
 * @returns page title string for the page title meta tag
 */
const constructMetaTagPageTitle = (
  seoConfig: ISeoState,
  formattedPath: string
): string => {
  const currentSeoConfig = seoConfig && seoConfig[formattedPath];

  const urlsPageTitle =
    currentSeoConfig && seoConfig[formattedPath].page?.title;

  let metaPageTitle = '';

  if (urlsPageTitle) {
    if (urlsPageTitle && urlsPageTitle.includes('| NorthernTool')) {
      metaPageTitle = urlsPageTitle.replace(
        '| NorthernTool',
        ' | Northern Tool'
      );
    } else {
      metaPageTitle = urlsPageTitle;
    }
  } else {
    metaPageTitle = 'Northern Tool - Quality Tools for Serious Work';
  }

  return metaPageTitle;
};

/**
 * @method constructPageTitleFromSeo Constructs page title from the seo title.
 *
 * @param seoConfig
 * @param formattedPath
 * @returns
 */
const constructPageTitleFromSeo = (
  seoConfig: ISeoState,
  formattedPath: string
): string => {
  const currentSeoConfig = seoConfig && seoConfig[formattedPath];

  const urlsPageTitle =
    currentSeoConfig && seoConfig[formattedPath].page?.title;

  if (urlsPageTitle && urlsPageTitle.includes('| NorthernTool')) {
    return urlsPageTitle.replace('| NorthernTool', '');
  }

  return '';
};

/**
 * @method scrollToTop responsible to scroll the page to top
 */
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

/**
 * @const isMobileDevicePDP is responsible forchecking width
 * of the screen less than mobileDeviceMaxWidth or not.
 */
const mobileDeviceMaxWidth = 959;

const isMobileDevicePDP = window.innerWidth <= mobileDeviceMaxWidth;

/**
 * @method getGcShippingMessages Maps the GC messages based on partNumber.
 *
 * @param partNumber
 */
const getGcShippingMessages = (
  partNumbers: string[],
  totalNoOfItems: number
): string => {
  const gcMessages = {
    'HL-': 'Welcome Packet by Mail within 2 Weeks',
    NetGC: 'Arrives by Mail within 1 Week',
    VNetGC: 'Emailed within 24 Hours',
    FREEGC: 'Arrives by Mail within 1 Week after Order Ships',
    VFREEGC: 'Emailed within 24 Hours after Order Ships or is Picked Up',
  };

  const gcMsgKeys = Object.keys(gcMessages);

  let currentMsgIndex = -1;

  let currentMessage = '';

  if (partNumbers.length === 1) {
    gcMsgKeys.forEach((key, index) => {
      if (partNumbers[0].startsWith(key)) {
        currentMsgIndex = index;
      }
    });
  }

  if (partNumbers.length > 1) {
    const physicalGcNo = partNumbers.filter((partNumber) =>
      partNumber.startsWith('NetGC')
    ).length;

    const netGcNo = partNumbers.filter((partNumber) =>
      partNumber.startsWith('VNetGC')
    ).length;

    if (
      physicalGcNo >= 1 &&
      netGcNo >= 1 &&
      partNumbers.length === totalNoOfItems
    ) {
      currentMessage = 'Arrives in 1-5 days';
    }

    if (physicalGcNo >= 1 && physicalGcNo === totalNoOfItems) {
      currentMessage = 'Arrives by Mail within 1 week';
    }

    if (netGcNo >= 1 && netGcNo === totalNoOfItems) {
      currentMessage = 'Emailed within 24 Hours';
    }
  }

  const currentMsgKey = gcMsgKeys[currentMsgIndex];

  if (currentMsgIndex !== -1 && hasKey(gcMessages, currentMsgKey)) {
    currentMessage = gcMessages[currentMsgKey];
  }

  return currentMessage;
};

/**
 * @method is500SeriesError Returns bool flag based on the network error status codes for 500 series errors.
 *
 * @param axiosError
 */
const is500SeriesError = ({ response }: AxiosError): boolean =>
  response?.status === 500 ||
  response?.status === 501 ||
  response?.status === 502 ||
  response?.status === 503 ||
  response?.status === 504 ||
  response?.status === 505;

/**
 * @method is400Error Returns bool flag based on the network error status codes for 400 error.
 *
 * @param axiosError
 */
const is400Error = ({ response }: AxiosError): boolean =>
  response?.status === 400;

const appendPLPPathToChildCategory = (
  categoriesData: StoreMenu[],
  path: string
) => {
  return categoriesData.map((category: StoreMenu) => {
    if (category.children) {
      category.children = appendPLPPathToChildCategory(category.children, path);
    }
    if (category?.seo?.href) {
      category.seo.href = path + category.seo.href;
    }
    return category;
  });
};

const appendPLPPathToBrandIndex = (
  categoriesData: ICategoryChildren[],
  path: string
) => {
  return categoriesData.map((category: ICategoryChildren) => {
    if (category.children) {
      category.children = appendPLPPathToBrandIndex(category.children, path);
    }
    if (category?.seo?.href) {
      category.seo.href = path + category.seo.href;
    }
    return category;
  });
};

/**
 * @method getYearDifference Returns the difference in years between current date and provided date
 *
 * * @param date
 */
const getYearDifference = (date: string) => {
  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();

  let day: Number;
  let month: Number;
  let year: Number;

  if (date.includes('OVR')) {
    const arr2 = date.split('OVR_')[1].split('/');
    month = Number(arr2[0]);
    day = Number(arr2[1]);
    year = Number(arr2[2]);
  } else if (date.includes('/')) {
    const arr1 = date.split('/');
    month = Number(arr1[0]);
    day = Number(arr1[1]);
    year = Number(arr1[2]);
  } else {
    return true;
  }

  const result = differenceInYears(
    new Date(yyyy, mm, dd),
    new Date(Number(year), Number(month), Number(day))
  );

  return result !== 0;
};

/**
 * @method currentDate Returns the current date in MM/DD/YYYY string format
 */
const currentDate = () => {
  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();

  const todayDate = String(mm + '/' + dd + '/' + yyyy);
  return todayDate;
};

const videoSorting = (action: any) => {
  const videoMap = ['primary', '360'];

  const videoOrdered = [...videoMap].reverse();

  const videoSorter = (a: IProductVideos, b: IProductVideos) => {
    if (a.custom_fields?.video_type && b.custom_fields?.video_type)
      return (
        videoOrdered.indexOf(a.custom_fields?.video_type) -
        videoOrdered.indexOf(b.custom_fields?.video_type)
      );
  };

  const productThumbNail = action.sort(videoSorter).reverse();

  return productThumbNail;
};

const isValidRegx = (currentValue: string) => {
  let isValid = true;
  try {
    new RegExp(currentValue);
  } catch (e) {
    isValid = false;
  }
  return isValid;
};

/**
 * @method encodeSpecialChars
 *
 * @prop value to be encoded.
 *
 * The purpose of this method is to encode the special chars for the browser url's
 * query param to it's respective encoded value. (For Example: # --> %23)
 *
 * We are encoding the chars in the query param to prevent the browser default char encoding which messes with our code logic.
 *
 * Special work around was made for the following symbols "+", "&" and "%" by adding "\p" instead of "%" in front of
 * their encoded value to prevent the browser from automatically encoding these symbols to browser default encode values
 *
 * For Example: "+" symbol automatically gets encoded by browser as "%20" instead of it's actual encoded value "%2B"
 *
 * Note: react-router history automatically decodes this encoded value returned by the function (ref: history.js from node modules).
 */
const encodeSpecialChars = (value: string): string => {
  const encodedValue = value
    .replace(/%/g, '\\p25')
    .replace(/\*/g, `%2A`)
    .replace(/-/g, '%2D')
    .replace(/_/g, '%5F')
    .replace(/\./g, '%2E')
    .replace(/!/g, '%21')
    .replace(/~/g, '%7E')
    .replace(/\*/g, '%2A')
    .replace(/'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/\[/g, '%5B')
    .replace(/\]/g, '%5D')
    .replace(/\+/g, '\\p2B')
    .replace(/&/g, '\\p26')
    .replace(/\{/g, '%7B')
    .replace(/\}/g, '%7D')
    .replace(/\|/g, '%7C')
    .replace(/:/g, '%3A')
    .replace(/;/g, '%3B')
    .replace(/,/g, '%2C')
    .replace(/</g, '%3C')
    .replace(/>/g, '%3E')
    .replace(/\?/g, '%3F')
    .replace(/'/g, '%27')
    .replace(/"/g, '%22')
    .replace(/#/g, '\\p23');

  return encodedValue;
};

const removeQueryParamBackslash = (value: string): string =>
  value.replaceAll('\\', '');

/**
 * @method formateProductSpecAttributeValue Formats the product key spec attribute values
 * incase the value comes as y or n from the BE.
 *
 * @param value to be formatted.
 * @param parseValue bool to return parsed value.
 */
const formateProductSpecAttributeValue = (
  value: string,
  parseValue?: boolean
): string | JSX.Element | JSX.Element[] => {
  const PRODUCT_SPECS_TRANSLATION = 'PRODUCT_SPECS.ATTRIBUTE_VALUES';

  if (
    value.toString().toLowerCase() === 'y' ||
    value.toString().toLowerCase() === 'n'
  ) {
    return value.toLowerCase() === 'y'
      ? i18n.t(`${PRODUCT_SPECS_TRANSLATION}.YES`)
      : i18n.t(`${PRODUCT_SPECS_TRANSLATION}.NO`);
  }

  return parseValue ? parse(value) : value;
};

/**
 * @method stringify the data.
 */
const stringify = (data: any): string => JSON.stringify(data);

/**
 * @method extractDecodedParams Converts a search string to an object.
 * Example: f=Brands:ABC|CDE+Ratings:5|4&index=0 => { 'f': 'Brands:ABC|CDE+Ratings:5|4', 'index': '0' }
 */
const extractDecodedParams = (searchString: string): EncodedQuery => {
  if (searchString) {
    const decodeParams = (queryString: string): string => decode(queryString);

    const decodedValues = qs.decode(searchString.split('?')[1]);

    if (decodedValues) {
      let query: EncodedQuery = {};

      const searchKeys = Object.keys(decodedValues);

      const searchValues = Object.values(decodedValues);

      searchKeys.forEach((searchKey: string, index: number) => {
        const searchValue = searchValues[index] as string;

        if (searchKey && !Array.isArray(searchValue)) {
          query = {
            ...query,
            [searchKey]: decodeParams(searchValue),
          };
        }
      });

      return query;
    }
  }

  const query: EncodedQuery = {};

  return query;
};

/**
 * @method searchStringToObject Converts a search string to an object for the QueryParamProvider wrapper.
 */
const searchStringToObject = (searchString: string): EncodedQuery => {
  const query: EncodedQuery = extractDecodedParams(searchString);

  return query;
};

/**
 * @method greaterThan365 Compares the current date and the end date
 * and returns true if the range between those 2 dates are more than an year.
 *
 * @param date Range end date
 */
const greaterThan365 = (date: string): boolean => {
  const interval: Interval = {
    end: new Date(date),
    start: new Date(),
  };

  const { years } = intervalToDuration(interval);

  if (years) {
    return years >= 1;
  }

  return false;
};

const compareObjectKeys = (data: Array<any>, compareKey: string) => {
  if (!(data && data.length)) {
    return false; //return false if the array is empty
  }

  let compare = data[0][compareKey];

  return data.every((item) => item[compareKey] === compare);
};

/**
 * @method formatAriaLabel formats the label string to aria label format.
 */
const formatAriaLabel = (label?: string): string =>
  label ? label.replaceAll('*', '').toLowerCase().split(' ').join('-') : '';

/*
 * @method formatTextWithDashes Formats a given regular text as dashed text
 * instead of spaces "Customer Number" as "customer-number"
 */
const formatTextWithDashes = (text?: string): string =>
  text ? text.toLowerCase().split(' ').join('-').replaceAll('*', '') : '';

function getAttributeValue(
  product: any,
  attributeIdentifier: string
): string | null {
  // Find the attribute with the specified identifier
  const attribute = product.attributes.find(
    (attr: { identifier: string }) => attr.identifier === attributeIdentifier
  );

  // If the attribute is found, return its value, otherwise return null
  return attribute?.values?.[0]?.value || null;
}

const filterShippingAddressesUtility = (
  contacts: IContactInformation[]
): IContactInformation[] => {
  if (!contacts || contacts.length === 0) return [];
  // Filter out only shipping addresses
  const shippingAddresses = contacts.filter(
    ({ addressType }) => addressType === AddressType.SHIPPING
  );

  if (shippingAddresses.length === 0) return [];

  // Find default shipping address (if any)
  const defaultIndex = shippingAddresses.findIndex(({ primary }) =>
    checkTruthy(primary?.toLowerCase())
  );

  if (defaultIndex > -1) {
    const [defaultAddress] = shippingAddresses.splice(defaultIndex, 1);
    return [defaultAddress, ...shippingAddresses];
  }

  return shippingAddresses;
};

export {
  NUMBERS,
  addStaticQueryParams,
  alertMessageId,
  allowGeoCoordinates,
  appendPLPPathToBrandIndex,
  appendPLPPathToChildCategory,
  calculateGrandTotal,
  calculateTax,
  checkMembership,
  checkSeeAllMenuItem,
  checkTruthy,
  clearStoreSession,
  compareCategorySequence,
  compareObjectKeys,
  constructBrandsImgName,
  constructESpotId,
  constructMetaTagPageTitle,
  constructPageTitleFromSeo,
  convertStrToNumber,
  convertTo12HFormat,
  createDebouncer,
  currentDate,
  customAxiosError,
  decodeQueryParams,
  deleteCookie,
  encodeSpecialChars,
  enterKeyPressHandler,
  extractDecodedParams,
  extractEspotHtml,
  findCategoryById,
  formatAddressDetails,
  formatAriaLabel,
  formatBreadCrumbLink,
  formatFacetPrice,
  formatGiftCardNumber,
  formatOrderPrice,
  formatPrice,
  formatPriceWihoutSymbol,
  formatQueryParams,
  formatSearchTerm,
  formatSeoUrlToString,
  formatSiteMapHeading,
  formatTextWithDashes,
  formatTime,
  formatToDollars,
  formatToTitleCase,
  formatToUrlString,
  formatUrlToTitleCase,
  formateProductSpecAttributeValue,
  formattedDateRange,
  generateAlphabets,
  generateNumbers,
  generatePickupPersonNickName,
  genericErrorAlert,
  getAMOrPM,
  getAttributeValue,
  getCookie,
  getCurrentDay,
  getEnumKeyByValue,
  getGcShippingMessages,
  getHighestDateRange,
  getLocalStorage,
  getLowestDateRange,
  getOrderFilterOptions,
  getRegionAdForStore,
  getSessionStorage,
  getShippingDate,
  getShippingDay,
  getStoreName,
  getTrackOrderUrl,
  getYearDifference,
  greaterThan365,
  hasKey,
  is400Error,
  is500SeriesError,
  isIos,
  isLaunchedAsPwa,
  isMobileDevicePDP,
  isNetworkError,
  isStoreAvailable,
  isValidRegx,
  parseBreadCrumbLinksToUtagData,
  parseLinksToUtagCategoryBrandData,
  parseLinkstoUtagPageData,
  removeDuplicatePromo,
  removeLocalStorage,
  removeQueryParamBackslash,
  scrollToTop,
  searchStringToObject,
  sendTealiumData,
  setCookie,
  setLocalStorage,
  setStoreSession,
  stringify,
  stripSeeAllItemCount,
  updateContinueShopping,
  updateRviData,
  videoSorting,
  filterShippingAddressesUtility,
  autoCompleteFocusHandler,
};
